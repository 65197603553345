import { Alert, Heading, VFlow } from 'bold-ui'
import { BoxGroup } from 'components/BoxGroup'
import { LoadingIndicator } from 'components/loading'
import { useAlterarConfiguracaoAnexoArquivosMutation, useAnexoArquivosQuery } from 'graphql/hooks.generated'
import { useConfiguracoes } from 'hooks/configuracoes'
import React, { useState } from 'react'

import { AlterarDiretorioAnexoArquivosModal } from './components/AlterarDiretorioAnexoArquivosModal'
import HabilitarAnexoArquivosBox from './components/HabilitarAnexoArquivosBox'
import { ConfiguracaoAnexoArquivosModel } from './model-anexoArquivos'

export function AnexoArquivosView() {
  const { data, loading } = useAnexoArquivosQuery()

  const anexos = data?.anexoArquivos

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const handleAlterarClick = () => setIsEditing(true)

  const handleModalClose = () => setIsEditing(false)

  const { update } = useConfiguracoes()
  const [alterarAnexoArquivosMutation] = useAlterarConfiguracaoAnexoArquivosMutation()

  const updateAnexoArquivosConfiguration = (habilitadoValue: boolean, formValues: ConfiguracaoAnexoArquivosModel) =>
    alterarAnexoArquivosMutation({
      variables: {
        input: {
          habilitado: habilitadoValue,
          diretorio: formValues.diretorio.trim(),
        },
      },
    }).then(() => update())

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={2}>Anexo de arquivos na instalação</Heading>
        {!anexos?.habilitado && (
          <Alert type='warning'>
            Para habilitar essa funcionalidade, certifique-se de que seu servidor possui a infraestrutura necessária
            para o armazenamento dos arquivos.
          </Alert>
        )}
      </VFlow>
      <AlterarDiretorioAnexoArquivosModal
        open={isEditing}
        onClose={handleModalClose}
        diretorio={anexos?.diretorio}
        onUpdateClick={updateAnexoArquivosConfiguration}
      />
      <BoxGroup>
        <HabilitarAnexoArquivosBox
          habilitado={anexos?.habilitado}
          diretorio={anexos?.diretorio}
          onAlterarClick={handleAlterarClick}
          onHabilitarClick={updateAnexoArquivosConfiguration}
        />
      </BoxGroup>
    </VFlow>
  )
}
