/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, FormControl, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, Text } from 'bold-ui'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps } from 'components/form'
import { FormApi } from 'final-form'
import { useIsDiretorioAnexoArquivosValidoLazyQuery } from 'graphql/hooks.generated'
import { ConfiguracoesModel } from 'hooks/configuracoes'
import { useEffect, useMemo, useState } from 'react'
import { FormSpy } from 'react-final-form'
import { AnexoArquivosStatusMessagesModel } from 'view/atendimentos/detail/soap/objetivo/anexo-arquivos/model-anexoArquivos'

import {
  CONFIG_ANEXO_ARQUIVOS_META_PATH,
  ConfiguracaoAnexoArquivosModel,
  VERIFICACAO_SUCESSO_CONFIG_ANEXO_ARQUIVOS_MSG,
} from '../model-anexoArquivos'
import { validate } from '../validator-anexoArquivos'
import { DiretorioAnexoArquivosField } from './DiretorioAnexoArquivosField'
import { DiretorioAnexoArquivosTestButton } from './DiretorioAnexoArquivosTestButton'

interface AlterarDiretorioAnexoArquivosModalProps {
  open: boolean
  onClose: () => void
  onUpdateClick: (
    habilitadoValue: boolean,
    formValues: ConfiguracaoAnexoArquivosModel
  ) => Promise<void | ConfiguracoesModel>
  diretorio: string
}

export function AlterarDiretorioAnexoArquivosModal(props: AlterarDiretorioAnexoArquivosModalProps) {
  const { open, onClose: handleClose, onUpdateClick: handleUpdateClick, diretorio } = props

  const alert = useAlert()
  const [statusMessage, setStatusMessage] = useState<AnexoArquivosStatusMessagesModel>(null)

  const [
    executeQueryTestDiretorio,
    { data: resultTestDiretorio, loading: isTestDiretorioLoading },
  ] = useIsDiretorioAnexoArquivosValidoLazyQuery()

  useEffect(() => {
    if (resultTestDiretorio) {
      setStatusMessage(
        resultTestDiretorio.isDiretorioAnexoArquivosValido
          ? {
              type: 'success',
              message: VERIFICACAO_SUCESSO_CONFIG_ANEXO_ARQUIVOS_MSG,
            }
          : { type: 'danger', message: null }
      )
    }
  }, [resultTestDiretorio])

  const clearMessage = () => setStatusMessage(null)

  const handleCloseWrapper = () => {
    handleClose()
    clearMessage()
  }

  const handleSubmit = (formValues: ConfiguracaoAnexoArquivosModel, formApi: FormApi<ConfiguracaoAnexoArquivosModel>) =>
    handleUpdateClick(null, formValues)
      .then(() => {
        handleCloseWrapper()
        alert('success', 'Diretório de destino dos arquivos alterado com sucesso.')
        resetForm(formApi)
      })
      .catch(() => setStatusMessage({ type: 'danger', message: 'Existem campos preenchidos de forma incorreta.' }))

  const renderForm = (formRenderProps: FormRenderProps<ConfiguracaoAnexoArquivosModel>) => {
    const handleCloseComposed = composeHandlers(resetForm(formRenderProps.form), handleCloseWrapper)
    return (
      <Modal style={styles.modal} open={open} onClose={handleCloseComposed}>
        <ModalBody>
          <Grid gap={0.5}>
            <Cell>
              <Heading level={1}>Alterar diretório</Heading>
            </Cell>
            <Cell>
              <Text>
                Ao alterar o diretório de destino dos arquivos, todos os arquivos salvos no diretório anterior devem ser
                transferidos para o novo diretório. Caso esse processo não seja realizado, 
                <Text component='strong'>o sistema perderá a referência para todos os arquivos já anexados</Text>,
                resultando em erros ao tentar acessar esses arquivos.
              </Text>
            </Cell>
            <Cell>
              <Text>
                Certifique-se de que os arquivos sejam transferidos para o novo diretório ao realizar a alteração. 
                <Text component='strong'>
                  É recomendado que esse processo seja realizado em horários de baixo uso do sistema e que seja
                  realizado um backup dos arquivos anexados anteriormente.
                </Text>
              </Text>
            </Cell>
            {statusMessage?.message && (
              <Cell size={12}>
                <Alert type={statusMessage.type} onCloseClick={clearMessage} inline>
                  {statusMessage.message}
                </Alert>
              </Cell>
            )}
            <Cell size={7}>
              <DiretorioAnexoArquivosField
                name={CONFIG_ANEXO_ARQUIVOS_META_PATH.diretorio}
                hasServerSideError={statusMessage?.type === 'danger'}
              />
            </Cell>
            <Cell>
              <FormSpy
                subscription={{ active: true }}
                onChange={(state) =>
                  setStatusMessage(
                    state.active === CONFIG_ANEXO_ARQUIVOS_META_PATH.diretorio.alias ? statusMessage : null
                  )
                }
              />
              <FormControl label={<span>&nbsp;</span>}>
                <DiretorioAnexoArquivosTestButton
                  name={CONFIG_ANEXO_ARQUIVOS_META_PATH.diretorio}
                  isTestLoading={isTestDiretorioLoading}
                  executeQueryTestDiretorio={executeQueryTestDiretorio}
                />
              </FormControl>
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleCloseComposed}>Cancelar</Button>
            <Button kind='danger' onClick={formRenderProps.handleSubmit}>
              Alterar
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const initialValues = useMemo<ConfiguracaoAnexoArquivosModel>(() => ({ diretorio }), [diretorio])

  return (
    <Form<ConfiguracaoAnexoArquivosModel>
      onSubmit={handleSubmit}
      render={renderForm}
      initialValues={initialValues}
      validate={validate}
      suppressNotificationError
    />
  )
}

const resetForm = (formApi: FormApi<ConfiguracaoAnexoArquivosModel>) => () => {
  setTimeout(() => formApi.reset())
  formApi.getRegisteredFields().forEach((item) => formApi.resetFieldState(item))
}

const styles = {
  modal: css`
    width: 39.125rem;
  `,
}
