/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Link, Spinner, Theme, useTheme } from 'bold-ui'

import { useVisualizarArquivoAnexado } from '../hooks/useVisualizarArquivoAnexado'
import { ArquivoAtendimentoProfissionalHistorico } from '../model-anexoArquivos'

interface VisualizarArquivoAnexadoHistoricoLinkButtonProps {
  arquivoAnexado: ArquivoAtendimentoProfissionalHistorico
}

export function VisualizarArquivoAnexadoHistoricoLinkButton(props: VisualizarArquivoAnexadoHistoricoLinkButtonProps) {
  const { arquivoAnexado } = props
  const { handleVisualizarArquivoAnexado, loading } = useVisualizarArquivoAnexado()
  const theme = useTheme()
  const styles = createStyles(theme)
  const arquivo = arquivoAnexado.arquivo

  // TODO (Legacy): #24194
  return loading ? (
    <Spinner size={1.3} style={styles.spinner} />
  ) : (
    <Link
      role='button'
      tabIndex={0}
      onClick={() => handleVisualizarArquivoAnexado(arquivo.id, arquivoAnexado.categoria)}
    >
      {arquivo.formato.toUpperCase()}
    </Link>
  )
}

const createStyles = (theme: Theme) => ({
  spinner: css`
    color: ${theme.pallete.primary.main};
    padding: 0.2rem 0rem;
    display: flex;
    align-items: center;
  `,
})
