import { Button, Cell, FormControl, Grid, Heading, HFlow, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { SubmitButton } from 'components/form'
import { useIsDiretorioAnexoArquivosValidoLazyQuery } from 'graphql/hooks.generated'
import React, { useEffect } from 'react'
import { FormSpy } from 'react-final-form'
import { AnexoArquivosStatusMessagesModel } from 'view/atendimentos/detail/soap/objetivo/anexo-arquivos/model-anexoArquivos'

import {
  CONFIG_ANEXO_ARQUIVOS_META_PATH,
  VALIDATION_ERROR_CONFIG_ANEXO_ARQUIVOS_MSG,
  VERIFICACAO_SUCESSO_CONFIG_ANEXO_ARQUIVOS_MSG,
} from '../model-anexoArquivos'
import { DiretorioAnexoArquivosField } from './DiretorioAnexoArquivosField'
import { DiretorioAnexoArquivosTestButton } from './DiretorioAnexoArquivosTestButton'

interface HabilitarAnexoArquivosFormProps {
  habilitado: boolean
  diretorio: string
  hasServerSideError: boolean
  setHasServerSideError: (err: boolean) => void
  handleSubmit: (event?: React.SyntheticEvent<HTMLFormElement>) => void
  onAlterarClick: () => void
}

export function HabilitarAnexoArquivosForm(props: HabilitarAnexoArquivosFormProps) {
  const {
    habilitado,
    diretorio,
    handleSubmit,
    hasServerSideError,
    setHasServerSideError,
    onAlterarClick: handleAlterarClick,
  } = props

  const alert = useAlert()

  const [
    executeQueryTestDiretorio,
    { data: resultTestDiretorio, loading: isTestDiretorioLoading },
  ] = useIsDiretorioAnexoArquivosValidoLazyQuery()

  useEffect(() => {
    if (resultTestDiretorio) {
      const alertData: AnexoArquivosStatusMessagesModel = resultTestDiretorio.isDiretorioAnexoArquivosValido
        ? {
            type: 'success',
            message: VERIFICACAO_SUCESSO_CONFIG_ANEXO_ARQUIVOS_MSG,
          }
        : { type: 'danger', message: VALIDATION_ERROR_CONFIG_ANEXO_ARQUIVOS_MSG }

      setHasServerSideError(!resultTestDiretorio.isDiretorioAnexoArquivosValido)
      alert(alertData.type, alertData.message)
    }
  }, [alert, resultTestDiretorio, setHasServerSideError])

  return (
    <Box>
      <VFlow>
        <HFlow hSpacing={0.5}>
          <Heading level={3}>Habilitar anexo de arquivos</Heading>
          {habilitado ? (
            <Tag type='success' icon='checkCircleFilled'>
              Habilitado
            </Tag>
          ) : (
            <Tag type='normal' icon='banOutline'>
              Desabilitado
            </Tag>
          )}
        </HFlow>
        <Text>
          Ao habilitar o anexo de arquivos, os profissionais de saúde poderão anexar arquivos ao prontuário de um
          cidadão caso necessário. Esses arquivos serão armazenados no diretório informado abaixo e podem ser
          visualizados no prontuário do cidadão por todos os profissionais que tiverem acesso. Imagens e documentos de
          saúde podem ocupar um espaço considerável de armazenamento e é necessário um monitoramento para garantir que a
          infraestrutura está suportando a demanda.
        </Text>
        <Grid gap={0.5}>
          <Cell size={5}>
            <DiretorioAnexoArquivosField
              name={CONFIG_ANEXO_ARQUIVOS_META_PATH.diretorio}
              hasServerSideError={hasServerSideError}
              disabled={!!diretorio}
            />
          </Cell>
          <Cell>
            <FormSpy
              subscription={{ active: true }}
              onChange={(state) =>
                setHasServerSideError(
                  state.active === CONFIG_ANEXO_ARQUIVOS_META_PATH.diretorio.alias ? hasServerSideError : false
                )
              }
            />
            <FormControl label={<span>&nbsp;</span>}>
              {!diretorio ? (
                <DiretorioAnexoArquivosTestButton
                  name={CONFIG_ANEXO_ARQUIVOS_META_PATH.diretorio}
                  isTestLoading={isTestDiretorioLoading}
                  executeQueryTestDiretorio={executeQueryTestDiretorio}
                />
              ) : (
                <Tooltip
                  text={habilitado ? 'É necessário desabilitar o anexo de arquivos para alterar o diretório' : ''}
                >
                  <Button kind='danger' skin='outline' disabled={habilitado} size='small' onClick={handleAlterarClick}>
                    Alterar diretório
                  </Button>
                </Tooltip>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <SubmitButton kind={habilitado ? 'normal' : 'primary'} size='small' handleSubmit={handleSubmit}>
          {habilitado ? 'Desabilitar' : 'Habilitar'}
        </SubmitButton>
      </VFlow>
    </Box>
  )
}
