import { ImpressaoAtendimentoInput } from 'api/ImpressaoAtendimentoIndividual'
import { modal } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import {
  getFirstGraphqlError,
  handleError,
  handleValidationError,
  isValidationError,
  useErrorHandler,
} from 'components/error'
import { Form, FormRenderProps } from 'components/form'
import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import changeValue from 'components/form/mutators/changeValue'
import { confirm } from 'components/modals/confirm'
import { ValidationErrorsModal } from 'components/modals/fields-summary'
import { FlagsContextModel, useFlags } from 'config/useFlagsContext'
import { parseISO, toDate } from 'date-fns'
import { Decorator, FormApi, FormState, FormSubscription } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { useApolloClient } from 'graphql/hooks'
import {
  useCancelarAtendimentoMutation,
  useRetificarAtendimentoMutation,
  useSalvarAtendimentoIndividualMutation,
  useSalvarAtendimentoIndividualParaAprovacaoMutation,
} from 'graphql/hooks.generated'
import {
  AtendimentoIndividualInput,
  InativarAtendimentoInput,
  TipoAtendimentoProfissional,
  TipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'
import { useServerTime } from 'hooks/useServerTime'
import React, { Dispatch, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { ErrorObject } from 'util/validation'
import { podeFinalizarAtendimentoObservacao } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import {
  CidadaoCalculator,
  createAtendimentoIndividualCalculator,
  PermissionsCalculator,
} from 'view/atendimentos/atendimento-individual/calculator-atendimentoIndividual'
import { convertAtendimentoIndividualModelToInput } from 'view/atendimentos/atendimento-individual/converter'
import { logEventCancelarAtendimento } from 'view/atendimentos/atendimento-individual/logCancelarAtendimento'
import { logEventFinalizarAtendimento } from 'view/atendimentos/atendimento-individual/logFinalizarAtendimento'
import { CiapCidPreNatal, meta, SoapState, TipoServicoModel } from 'view/atendimentos/atendimento-individual/model'
import { atendimentoIndividualValidator } from 'view/atendimentos/atendimento-individual/validator-atendimentoIndividual'
import { validateCpfCns } from 'view/atendimentos/atendimento-vacinacao/validator'
import { ProcedimentoAutomatico, RegistroAvaliacaoPuericultura } from 'view/atendimentos/model-atendimento'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'
import { useJustificativaVerProntuarioId } from 'view/prontuario/justificativa/useJustificativaVerProntuarioId'
import { REGISTRO_TARDIO_PATH } from 'view/registro-tardio/RegistroTardioRootView'
import { retificacaoAtendimentoConfirmModal } from 'view/retificacao-atendimento/retificacaoAtendimentoConfirmModal'
import { useRetificacaoStorage } from 'view/retificacao-atendimento/useJustificativaRetificacaoStorage'

import { useVerificarAgendamentosConflitantes } from '../../../agenda/hooks/useVerificarAgendamentosConflitantes'
import { atendimentoFooterMessage } from '../atendimentoFooterMessage'
import { AtendimentoIndividualFooter } from '../AtendimentoIndividualFooter'
import { downloadAtendimentoIndividual } from '../components/downloadAtendimentoIndividual'
import { PeriodoGestacaoModel } from '../components/modals/types/PeriodoGestacaoModel'
import { createVacinacaoErrorModal } from '../vacinacao/VacinacaoCalendarioView'
import { Problema } from './aside/types/ProblemaModel'
import { hasProblemaComCiapW78Ativo } from './avaliacao/components/problemas-condicoes/utils/verifications-problemasCondicoes'
import { dirtyFieldsModalConfig, soapValidationErrorModalConfig } from './fields-summary-modal/soapFieldModalConfigs'
import { soapFormLabels } from './fields-summary-modal/soapFormLabels'
import { convertSoapErrorsFromBackend } from './fields-summary-modal/validation-errors-modal/converter-soapErrorsFromBackend'
import { convertAgendarConsultasToAgendamentosConflitantesInput } from './finalizacao'
import { grupoCboProcedimentosAutomaticos } from './plano/acessos'
import { HasAccessProcedimentos } from './plano/calculator-plano-medicoes'
import { SoapPage } from './SoapPage'
import { SoapRootView } from './SoapRootView'
import { handleKeyDown } from './util'

const subscription: FormSubscription = {} /* evita problemas de performance */

const createDecorators = (
  apollo,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  intervencoesProcedimentosAutomaticos: ProcedimentoSelectModel[],
  registroAvaliacaoPuericultura: RegistroAvaliacaoPuericultura,
  cbo2002: string,
  tipoAtendimentoProfissional: TipoAtendimentoProfissional,
  prontuarioId: ID,
  ciapCidPreNatal: CiapCidPreNatal,
  dataAtendimento: Instant,
  cidadao: CidadaoCalculator,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  permissions: PermissionsCalculator,
  ultimaDumPreNatalAtivo: LocalDate,
  isRegistroTardio: boolean,
  isObservacaoAndResponsavel: boolean,
  flags: FlagsContextModel,
  hasProblemaComCiapW78AtivoPersistido: boolean
): Decorator[] => [
  createAtendimentoIndividualCalculator({
    apollo,
    procedimentosAutomaticos,
    intervencoesProcedimentosAutomaticos,
    registroAvaliacaoPuericultura,
    cbo2002,
    tipoAtendimentoProfissional,
    prontuarioId,
    ciapCidPreNatal,
    dataAtendimento,
    cidadao,
    tipoEstabelecimento,
    permissions,
    ultimaDumPreNatalAtivo,
    isRegistroTardio,
    isObservacaoAndResponsavel,
    hasProblemaComCiapW78AtivoPersistido,
  }),
]

export interface SoapViewProps {
  atendimentoId: ID
  prontuarioId: ID
  cidadao: CidadaoAtendimento
  isRegistroTardio: boolean
  tiposServico: TipoServicoModel[]
  dataAtendimento: Instant
  procedimentosAutomaticos?: ProcedimentoAutomatico[]
  intervencoesProcedimentosAutomaticos: ProcedimentoSelectModel[]
  registroAvaliacaoPuericultura: RegistroAvaliacaoPuericultura
  cbo2002: string
  agendamentoAtendimentoId: ID
  concluiAgendamento: boolean
  hasEncaminhamentoExterno: boolean
  agendamentosDia: AgendamentoDia[]
  atendimentoProfissional: AtendimentoProfissional
  nomeAtendimento: string
  ciapCidPreNatal: CiapCidPreNatal
  cacheState: SoapState
  dirtyInitialValues: SoapState
  gestacoes: PeriodoGestacaoModel[]
  ultimaDumPreNatalAtivo: LocalDate
  problemasAtivosELatentes?: Problema[]
  problemasResolvidos?: Problema[]
  updateCache: Dispatch<SoapState>
  clearCache(updateState?: boolean): void
  headerHeight: number
  qtdTotalProcedimentos: number
  preNatalAtivoHasTipoGravidez: boolean
  hasIvcfPreenchidoEmAtendObservacaoAnterior: boolean
}

export function SoapView(props: SoapViewProps) {
  const { cacheState, dirtyInitialValues } = props
  const [initialValues] = useState(cacheState) // usado pra setar os valores iniciais do Form apenas na primeira vez que renderizar
  return (
    <div>
      <SoapForm {...props} dirtyInitialValues={dirtyInitialValues} initialValues={initialValues} />
    </div>
  )
}

export interface SoapFormProps extends SoapViewProps {
  initialValues: SoapState
}

const SoapForm = memo((props: SoapFormProps) => {
  const {
    atendimentoId,
    prontuarioId,
    isRegistroTardio,
    agendamentosDia,
    dataAtendimento,
    procedimentosAutomaticos,
    intervencoesProcedimentosAutomaticos,
    registroAvaliacaoPuericultura,
    initialValues,
    dirtyInitialValues,
    agendamentoAtendimentoId,
    concluiAgendamento,
    cbo2002,
    hasEncaminhamentoExterno,
    cidadao,
    atendimentoProfissional,
    updateCache,
    clearCache,
    nomeAtendimento,
    ciapCidPreNatal,
    problemasAtivosELatentes,
    problemasResolvidos,
    gestacoes,
    ultimaDumPreNatalAtivo,
    headerHeight,
    qtdTotalProcedimentos,
    preNatalAtivoHasTipoGravidez,
    hasIvcfPreenchidoEmAtendObservacaoAnterior,
  } = props

  const history = useHistory()
  const [finalizar] = useSalvarAtendimentoIndividualMutation()
  const [retificar] = useRetificarAtendimentoMutation()
  const [enviarParaAprovacao] = useSalvarAtendimentoIndividualParaAprovacaoMutation()
  const [cancelar, { loading: isLoadingCancelar }] = useCancelarAtendimentoMutation()
  const { verificarAgendamentosConflitantes } = useVerificarAgendamentosConflitantes()
  const handleRejection = useErrorHandler()
  const { data, isEstagio } = useSession()
  const apollo = useApolloClient()
  const dataNascimentoCidadao = Number(parseISO(cidadao?.dataNascimento))
  const { getServerTimeNow } = useServerTime()
  const dataReferencia = atendimentoProfissional?.iniciadoEm
  const tipoAtendProf = atendimentoProfissional?.tipo
  const { analytics } = useFirebase()
  const { getRetificacaoStorageValue, deleteStorageRetificacao } = useRetificacaoStorage(atendimentoId)
  const flags = useFlags()
  const { callbackUrl: retificacaoCallbackUrl, justificativaVerProntuarioId } = getRetificacaoStorageValue()

  const {
    cidadao: { isGestante },
    permissoes: { hasPermissionPreNatal, hasPermissionAcompanhamentoPreNatal, somenteCiap },
    observacao: { isAtendimentoObservacao, isObservacaoAndResponsavel },
    tiposAtendimento: { isAtendimentoOdonto },
    statusRevisaoAtendimento,
    isRetificacao,
  } = useAtendimentoContext()

  const cidadaoBaseUrl = `/cidadao/${cidadao.id}`
  const [, setJustificativaVerProntuarioId] = useJustificativaVerProntuarioId(cidadaoBaseUrl, cidadao.id)

  const { hasCboAuth, tipoEstabelecimento } = useSession({ fetchPolicy: 'cache-only' })
  const { acesso } = useAcessoLotacaoOrEstagio()
  const podeFinalizarObservacao = podeFinalizarAtendimentoObservacao(acesso, tipoEstabelecimento)

  const callbackUrl = isRetificacao
    ? retificacaoCallbackUrl
    : isRegistroTardio
    ? REGISTRO_TARDIO_PATH
    : '/lista-atendimento'

  const hasAccessProcedimentos: HasAccessProcedimentos = useMemo(
    () => ({
      desenvolvimentoCrianca: hasCboAuth(grupoCboProcedimentosAutomaticos.desenvolvimentoCrianca),
      crescimentoCrianca: hasCboAuth(grupoCboProcedimentosAutomaticos.crescimentoCrianca),
      glicemiaCapilar: hasCboAuth(grupoCboProcedimentosAutomaticos.glicemiaCapilar),
    }),
    [hasCboAuth]
  )
  const hasProblemaComCiapW78AtivoPersistido = hasProblemaComCiapW78Ativo(problemasAtivosELatentes)

  const decorators = useMemo(
    () =>
      createDecorators(
        apollo,
        procedimentosAutomaticos,
        intervencoesProcedimentosAutomaticos,
        registroAvaliacaoPuericultura,
        cbo2002,
        tipoAtendProf,
        prontuarioId,
        ciapCidPreNatal,
        dataAtendimento,
        {
          dataNascimento: cidadao.dataNascimento,
          problemasAtivosELatentes,
          isGestante,
          idadeEmAnos: cidadao.idadeEmAnos,
          desejaInformarIdentidadeGenero: cidadao.desejaInformarIdentidadeGenero,
          sexo: cidadao.sexo,
        },
        tipoEstabelecimento,
        { hasAccessProcedimentos, hasPermissionPreNatal, hasPermissionAcompanhamentoPreNatal },
        ultimaDumPreNatalAtivo,
        isRegistroTardio,
        isObservacaoAndResponsavel,
        flags,
        hasProblemaComCiapW78AtivoPersistido
      ),
    [
      apollo,
      procedimentosAutomaticos,
      intervencoesProcedimentosAutomaticos,
      registroAvaliacaoPuericultura,
      cbo2002,
      tipoAtendProf,
      prontuarioId,
      ciapCidPreNatal,
      dataAtendimento,
      cidadao.dataNascimento,
      cidadao.idadeEmAnos,
      cidadao.desejaInformarIdentidadeGenero,
      cidadao.sexo,
      problemasAtivosELatentes,
      isGestante,
      tipoEstabelecimento,
      hasAccessProcedimentos,
      hasPermissionPreNatal,
      hasPermissionAcompanhamentoPreNatal,
      ultimaDumPreNatalAtivo,
      isRegistroTardio,
      isObservacaoAndResponsavel,
      flags,
      hasProblemaComCiapW78AtivoPersistido,
    ]
  )
  const validators = useMemo(
    () =>
      atendimentoIndividualValidator(
        { ...cidadao, isGestante },
        dataAtendimento,
        tipoAtendProf,
        problemasAtivosELatentes,
        !concluiAgendamento,
        hasEncaminhamentoExterno,
        hasPermissionPreNatal,
        isAtendimentoObservacao,
        isObservacaoAndResponsavel,
        podeFinalizarObservacao,
        getServerTimeNow,
        qtdTotalProcedimentos,
        preNatalAtivoHasTipoGravidez,
        hasProblemaComCiapW78AtivoPersistido,
        hasIvcfPreenchidoEmAtendObservacaoAnterior
      ),
    [
      cidadao,
      isGestante,
      dataAtendimento,
      tipoAtendProf,
      problemasAtivosELatentes,
      concluiAgendamento,
      hasEncaminhamentoExterno,
      hasPermissionPreNatal,
      isAtendimentoObservacao,
      isObservacaoAndResponsavel,
      podeFinalizarObservacao,
      getServerTimeNow,
      qtdTotalProcedimentos,
      preNatalAtivoHasTipoGravidez,
      hasProblemaComCiapW78AtivoPersistido,
      hasIvcfPreenchidoEmAtendObservacaoAnterior,
    ]
  )

  const alert = useAlert()
  const { PILOTO_ALERTAS_SDC_1_ENABLED } = useFlags()

  const nomeCompletoAtendimento = `Atendimento ${nomeAtendimento}`

  const printItem = (itemToPrint: ImpressaoAtendimentoInput) => {
    downloadAtendimentoIndividual(itemToPrint)
  }

  const { setSdcAtendimentoValues } = useAlertasSdc()
  const [formInitialized, setFormInitialized] = useState(false)
  useEffect(() => {
    setFormInitialized(true)
    PILOTO_ALERTAS_SDC_1_ENABLED &&
      setSdcAtendimentoValues({
        problemasCondicoes: initialValues?.avaliacao?.problemasECondicoes,
        prontuarioId: prontuarioId,
        atendProfData: toDate(atendimentoProfissional.iniciadoEm),
      })
  }, [
    PILOTO_ALERTAS_SDC_1_ENABLED,
    atendimentoProfissional.iniciadoEm,
    initialValues,
    prontuarioId,
    setSdcAtendimentoValues,
  ])

  const handleFormChange = useCallback(
    (values: SoapState) => {
      /* Avoids the error of updating a component while rendering a different component. */
      if (formInitialized) {
        values.lastSaved = new Date()
        PILOTO_ALERTAS_SDC_1_ENABLED &&
          setSdcAtendimentoValues({
            problemasCondicoes: values?.avaliacao?.problemasECondicoes,
            prontuarioId: prontuarioId,
            atendProfData: toDate(atendimentoProfissional.iniciadoEm),
          })
        updateCache(values)
      }
    },
    [
      formInitialized,
      PILOTO_ALERTAS_SDC_1_ENABLED,
      setSdcAtendimentoValues,
      prontuarioId,
      atendimentoProfissional.iniciadoEm,
      updateCache,
    ]
  )

  const footerMessages = atendimentoFooterMessage(
    tipoAtendProf,
    isAtendimentoObservacao,
    isObservacaoAndResponsavel,
    isRetificacao
  )

  const goToCallbackUrl = useCallback(() => {
    justificativaVerProntuarioId && setJustificativaVerProntuarioId(justificativaVerProntuarioId)
    isRetificacao && deleteStorageRetificacao()

    // Necessário setTimeout para só navegar depois de atualizar os storages
    // TODO (RNG) #24441: Criar interface async para os "useStorage"
    clearCache(false)
    setTimeout(() => history.push(callbackUrl))
  }, [
    callbackUrl,
    clearCache,
    deleteStorageRetificacao,
    history,
    isRetificacao,
    justificativaVerProntuarioId,
    setJustificativaVerProntuarioId,
  ])

  const cancelarAtendimento = useCallback(
    (isStartObservacao) => async () => {
      try {
        await cancelar({ variables: { atendimentoId } })

        logEventCancelarAtendimento(
          analytics.logEvent,
          nomeCompletoAtendimento,
          isStartObservacao,
          isAtendimentoObservacao,
          atendimentoProfissional.finalizadoEm
        )

        goToCallbackUrl()
      } catch (e) {
        return handleRejection(e as any)
      }
    },
    [
      cancelar,
      atendimentoId,
      analytics.logEvent,
      nomeCompletoAtendimento,
      isAtendimentoObservacao,
      atendimentoProfissional.finalizadoEm,
      goToCallbackUrl,
      handleRejection,
    ]
  )

  const cancelarRetificacao = useCallback(() => {
    //TODO(RNG) #24375: Log de cancelamento de retificacao
    goToCallbackUrl()
  }, [goToCallbackUrl])

  const handleCancelar = useCallback(
    (isStartObservacao: boolean) => {
      confirm({
        title: footerMessages.confirmTitle,
        body: footerMessages.body,
        confirmLabel: 'Sim, cancelar',
        cancelLabel: 'Não, continuar aqui',
        onConfirm: !isRetificacao ? cancelarAtendimento(isStartObservacao) : cancelarRetificacao,
      })()
    },
    [footerMessages.confirmTitle, footerMessages.body, isRetificacao, cancelarAtendimento, cancelarRetificacao]
  )

  const handleClickAtualizarCadastroCidadao = useCallback(
    () =>
      history.push(`${cidadaoBaseUrl}/edit?callbackUrl=lista-atendimento/atendimento&callbackParams=${atendimentoId}`),
    [atendimentoId, cidadaoBaseUrl, history]
  )

  const handleRetificar = useCallback(
    async (justificativaRetificacao: string, atendimentoRetificadoInput: AtendimentoIndividualInput) => {
      const inativarAtendimentoInput: InativarAtendimentoInput = {
        atendimentoProfissionalId: atendimentoProfissional.id,
        justificativa: justificativaRetificacao,
      }

      const retificarResponse = await retificar({
        variables: {
          input: {
            atendimentoRetificadoInput,
            inativarAtendimentoInput,
          },
        },
      })

      //TODO(RNG) #23631: Impressão do atendimento retificado
      //TODO(RNG) #24375: Log de finalização do atendimento retificado
      alert('success', `${nomeCompletoAtendimento} retificado com sucesso.`)

      goToCallbackUrl()

      return retificarResponse
    },
    [atendimentoProfissional.id, retificar, alert, nomeCompletoAtendimento, goToCallbackUrl]
  )

  const handleFinalizar = useCallback(
    async (values: SoapState, formApi: FormApi<SoapState>) => {
      const errorCpfCnsRequired =
        values.vacinacao?.registroVacinacao?.length &&
        validateCpfCns(cidadao.cns || cidadao.cpf, handleClickAtualizarCadastroCidadao, false)

      if (errorCpfCnsRequired?.hasError) {
        createVacinacaoErrorModal(errorCpfCnsRequired)
      } else {
        const confirmouHorariosConflitantes = await verificarAgendamentosConflitantes(
          cidadao.id,
          convertAgendarConsultasToAgendamentosConflitantesInput(values.finalizacao?.agendamentoConsultas),
          cidadao.nomeSocial ?? cidadao.nome
        )

        if (!confirmouHorariosConflitantes) return

        const input = convertAtendimentoIndividualModelToInput(
          atendimentoId,
          values,
          dataNascimentoCidadao,
          dataReferencia,
          tipoAtendProf,
          isAtendimentoObservacao,
          isObservacaoAndResponsavel,
          isAtendimentoOdonto,
          flags
        )

        if (isEstagio) {
          const enviarAprovacaoResponse = await enviarParaAprovacao({
            variables: {
              input: {
                atendimentoInput: input,
                rascunho: JSON.stringify(values),
              },
            },
          })

          if (enviarAprovacaoResponse.data.salvarAtendimentoIndividualParaAprovacao) {
            analytics.logEvent('atendimento_enviado_revisao_EST', { tipo_de_atendimento: nomeCompletoAtendimento })
            alert('success', `${nomeCompletoAtendimento} enviado para aprovação com sucesso.`)
            goToCallbackUrl()
          }
        } else {
          try {
            if (!isRetificacao) {
              const finalizarResponse = await finalizar({
                variables: {
                  input,
                },
              })

              values.imprimirAtendimento &&
                printItem({
                  atendimentoProfissionalId: finalizarResponse.data.salvarAtendimentoIndividual.id,
                })

              logEventFinalizarAtendimento(
                analytics.logEvent,
                nomeCompletoAtendimento,
                values,
                cidadao.idadeEmAnos,
                isGestante,
                initialValues.antecedentes?.pessoal,
                isAtendimentoObservacao,
                statusRevisaoAtendimento
              )
              alert('success', `${nomeCompletoAtendimento} finalizado com sucesso.`)

              goToCallbackUrl()
            } else {
              const { initialValues, values } = formApi.getState()

              await retificacaoAtendimentoConfirmModal({
                initialValues,
                currentValues: values,
                nomeCidadao: (cidadao.nomeSocial ?? cidadao.nome).titleCase(),
                labels: soapFormLabels,
                config: dirtyFieldsModalConfig,
                meta,
                onRetificar: () => handleRetificar(values.justificativaRetificacao, input),
                onRetificarSemAlteracoes: goToCallbackUrl,
              })
            }
          } catch (error) {
            const firstError = getFirstGraphqlError(error)
            const handledError: ErrorObject<AtendimentoIndividualInput> = handleError({
              error,
              suppressNotificationError: true,
            })

            if (isValidationError(firstError)) {
              modal({
                size: 'auto',
                depthLevel: 5,
                render: () => (
                  <ValidationErrorsModal<SoapState>
                    errors={convertSoapErrorsFromBackend(handledError, flags, isAtendimentoOdonto)}
                    labels={soapFormLabels}
                    config={soapValidationErrorModalConfig}
                    meta={meta}
                  />
                ),
              })()

              // eslint-disable-next-line no-console
              console.error(
                `Erro ao finalizar atendimento ${tipoAtendProf}`,
                JSON.stringify(handleValidationError(firstError))
              )
            }

            throw error
          }
        }
      }
    },
    [
      cidadao.cns,
      cidadao.cpf,
      cidadao.id,
      cidadao.nomeSocial,
      cidadao.nome,
      cidadao.idadeEmAnos,
      handleClickAtualizarCadastroCidadao,
      verificarAgendamentosConflitantes,
      atendimentoId,
      dataNascimentoCidadao,
      dataReferencia,
      tipoAtendProf,
      isAtendimentoObservacao,
      isObservacaoAndResponsavel,
      isAtendimentoOdonto,
      flags,
      isEstagio,
      enviarParaAprovacao,
      analytics,
      nomeCompletoAtendimento,
      alert,
      isRetificacao,
      finalizar,
      isGestante,
      initialValues.antecedentes,
      statusRevisaoAtendimento,
      goToCallbackUrl,
      handleRetificar,
    ]
  )

  const handleSubmitFailed = (formState: FormState<SoapState>) => {
    const { errors, hasValidationErrors } = formState

    if (hasValidationErrors) {
      modal({
        size: 'auto',
        depthLevel: 5,
        render: () => (
          <ValidationErrorsModal<SoapState>
            errors={errors}
            labels={soapFormLabels}
            config={soapValidationErrorModalConfig}
            meta={meta}
          />
        ),
      })()
    }
  }

  const handleOnFocus = useCallback(() => {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) {
      const activeElementTop = activeElement.getBoundingClientRect().top
      activeElementTop < 0 && window.scrollTo(0, activeElement.parentElement.offsetTop - 160)
    }
  }, [])

  const renderForm = useCallback(
    (formProps: FormRenderProps<SoapState>): JSX.Element => {
      const { handleSubmit } = formProps

      return (
        <>
          <form
            noValidate
            onKeyDown={handleKeyDown}
            onFocus={handleOnFocus}
            style={{ display: 'flex', flexDirection: 'column', minHeight: '60vh' }}
          >
            <SoapPage
              atendimentoProfissional={atendimentoProfissional}
              dataAtualizacaoObstetricosAntecedentes={
                props.cacheState?.antecedentes?.pessoal?.informacoesObstetricas?.dataAtualizacao
              }
              dataAtualizacaoPuericulturaAntecedentes={
                props.cacheState?.antecedentes?.pessoal?.puericultura?.dataAtualizacao
              }
              cidadao={cidadao}
              dataAtendimento={dataAtendimento}
              prontuarioId={prontuarioId}
              ciapCidPreNatal={ciapCidPreNatal}
              acesso={data.acesso}
              agendamentoAtendimentoId={agendamentoAtendimentoId}
              concluiAgendamento={concluiAgendamento}
              agendamentosDia={agendamentosDia}
              atendimentoId={atendimentoId}
              handleFormChange={handleFormChange}
              profissionalId={data.profissional.id}
              gestacoes={gestacoes}
              problemasAtivosELatentes={problemasAtivosELatentes}
              problemasResolvidos={problemasResolvidos}
              headerHeight={headerHeight}
              hasProblemaComCiapW78AtivoPersistido={hasProblemaComCiapW78AtivoPersistido}
              hasIvcfPreenchidoEmAtendObservacaoAnterior={hasIvcfPreenchidoEmAtendObservacaoAnterior}
            />
            <AtendimentoIndividualFooter
              nomeAtendimento={nomeAtendimento}
              handleSubmit={handleSubmit}
              handleCancel={handleCancelar}
              cancelButtonLabel={footerMessages.labelButton}
              isLoadingCancelar={isLoadingCancelar}
            />
          </form>
          <SoapRootView
            atendimentoProfissional={atendimentoProfissional}
            cidadao={cidadao}
            dataAtendimento={dataAtendimento}
            isAtendimentoOdonto={isAtendimentoOdonto}
            prontuarioId={prontuarioId}
            ciapCidPreNatal={ciapCidPreNatal}
            somenteCiap={somenteCiap}
            isAtendimentoObservacao={isAtendimentoObservacao}
          />
        </>
      )
    },
    [
      handleOnFocus,
      atendimentoProfissional,
      props.cacheState,
      cidadao,
      dataAtendimento,
      prontuarioId,
      ciapCidPreNatal,
      data.acesso,
      data.profissional.id,
      agendamentoAtendimentoId,
      concluiAgendamento,
      agendamentosDia,
      atendimentoId,
      handleFormChange,
      gestacoes,
      problemasAtivosELatentes,
      problemasResolvidos,
      headerHeight,
      hasProblemaComCiapW78AtivoPersistido,
      hasIvcfPreenchidoEmAtendObservacaoAnterior,
      nomeAtendimento,
      handleCancelar,
      footerMessages.labelButton,
      isLoadingCancelar,
      isAtendimentoOdonto,
      somenteCiap,
      isAtendimentoObservacao,
    ]
  )

  return (
    <Form<SoapState>
      render={renderForm}
      initialValues={initialValues}
      dirtyInitialValues={dirtyInitialValues}
      decorators={decorators}
      validateOnBlur
      validate={validators}
      subscription={subscription}
      onSubmit={handleFinalizar}
      onSubmitFailed={handleSubmitFailed}
      mutators={{
        ...arrayMutators,
        changeValue,
      }}
    />
  )
})
