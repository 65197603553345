/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { Form } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { ConfiguracoesModel } from 'hooks/configuracoes'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useMemo, useState } from 'react'
import { FormRenderProps } from 'react-final-form'

import { ConfiguracaoAnexoArquivosModel } from '../model-anexoArquivos'
import { validate } from '../validator-anexoArquivos'
import { HabilitarAnexoArquivosForm } from './HabilitarAnexoArquivosForm'

interface HabilitarAnexoArquivosBoxProps {
  habilitado: boolean
  diretorio: string
  onAlterarClick: () => void
  onHabilitarClick: (
    habilitadoValue: boolean,
    formValues: ConfiguracaoAnexoArquivosModel
  ) => Promise<void | ConfiguracoesModel>
}

export default function HabilitarAnexoArquivosBox(props: HabilitarAnexoArquivosBoxProps) {
  const { habilitado, diretorio, onAlterarClick: handleAlterarClick, onHabilitarClick: handleHabilitarClick } = props

  const [hasServerSideError, setHasServerSideError] = useState<boolean>(false)
  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const { analytics } = useFirebase()

  const handleSubmit = (formValues: ConfiguracaoAnexoArquivosModel) => {
    const titleText = habilitado ? 'desabilitar' : 'habilitar'
    const bodyText = habilitado ? (
      'Ao desabilitar, os profissionais de saúde não poderão mais anexar arquivos ao prontuário de um cidadão, mas continuarão tendo acesso aos arquivos já anexados.'
    ) : (
      <VFlow>
        <Text>
          Ao habilitar, os profissionais de saúde poderão anexar arquivos ao prontuário de um cidadão e os arquivos
          serão armazenados no diretório informado.
        </Text>
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>Diretório de destino dos arquivos</Text>
          <Text style={styles.diretorioText}>{formValues.diretorio || diretorio}</Text>
        </VFlow>
      </VFlow>
    )
    const alertText = habilitado ? 'novos arquivos desabilitado' : ' arquivos habilitado'

    return confirm({
      title: `Deseja ${titleText} o anexo de arquivos?`,
      body: bodyText,
      onConfirm: () => {
        return handleHabilitarClick(!habilitado, formValues)
          .then(() => {
            setHasServerSideError(false)
            analytics.logEvent(`click_${titleText}_anexo_arquivos`)
            alert('success', `Anexo de ${alertText} com sucesso`)
          })
          .catch((err) => {
            setHasServerSideError(true)
            handleRejection(err)
          })
      },
    })()
  }

  const renderForm = (formProps: FormRenderProps<ConfiguracaoAnexoArquivosModel>) => (
    <HabilitarAnexoArquivosForm
      handleSubmit={formProps.handleSubmit}
      habilitado={habilitado}
      diretorio={diretorio}
      onAlterarClick={handleAlterarClick}
      hasServerSideError={hasServerSideError}
      setHasServerSideError={setHasServerSideError}
    />
  )

  const initialValues = useMemo(() => ({ diretorio }), [diretorio])

  return (
    <Form<ConfiguracaoAnexoArquivosModel>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={renderForm}
    />
  )
}

const styles = {
  diretorioText: css`
    word-break: break-all;
  `,
}
