import { TextField, usePecField } from 'components/form'
import { getFieldError } from 'components/form/final-form/util'
import React from 'react'
import { Meta } from 'util/metaPath'

import { VALIDATION_ERROR_CONFIG_ANEXO_ARQUIVOS_MSG } from '../model-anexoArquivos'

interface DiretorioAnexoArquivosFieldProps {
  name: Meta<string>
  hasServerSideError: boolean
  disabled?: boolean
}

export function DiretorioAnexoArquivosField(props: DiretorioAnexoArquivosFieldProps) {
  const { name, hasServerSideError, disabled = false } = props

  const { meta } = usePecField({ name: name })

  return (
    <TextField
      label='Diretório de destino dos arquivos'
      placeholder='Informe o caminho completo para o diretório'
      name={name}
      disabled={disabled}
      error={hasServerSideError ? VALIDATION_ERROR_CONFIG_ANEXO_ARQUIVOS_MSG : getFieldError(meta)}
      required
    />
  )
}
