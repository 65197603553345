import { createValidator, required } from 'util/validation'

import { MChatAplicacaoModel } from './model-mchat'

export const mChatRValidator = createValidator<MChatAplicacaoModel>({
  PERGUNTA_1: required,
  PERGUNTA_2: required,
  PERGUNTA_3: required,
  PERGUNTA_4: required,
  PERGUNTA_5: required,
  PERGUNTA_6: required,
  PERGUNTA_7: required,
  PERGUNTA_8: required,
  PERGUNTA_9: required,
  PERGUNTA_10: required,
  PERGUNTA_11: required,
  PERGUNTA_12: required,
  PERGUNTA_13: required,
  PERGUNTA_14: required,
  PERGUNTA_15: required,
  PERGUNTA_16: required,
  PERGUNTA_17: required,
  PERGUNTA_18: required,
  PERGUNTA_19: required,
  PERGUNTA_20: required,
})
