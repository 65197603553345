/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, colors, Grid, Heading, Text, VFlow } from 'bold-ui'
import { isUndefinedOrNull } from 'util/checks'

import { ValidationErrorModalItemModel } from './model-validationErrorsModal'
import { ValidationErrorsModalItem } from './ValidationErrorsModalItem'

interface ValidationErrorsModalSectionProps {
  section: ValidationErrorModalItemModel
}

export const ValidationErrorsModalSection = (props: ValidationErrorsModalSectionProps) => {
  const { section } = props

  if (isUndefinedOrNull(section?.fields) && isUndefinedOrNull(section?.error)) return null

  const hasSectionError = !isUndefinedOrNull(section.error)
  const isFieldsExpanded = section.fields?.length === 1

  return (
    <VFlow vSpacing={hasSectionError ? 0.25 : 0.5}>
      <Heading
        level={3}
        style={css`
          color: ${section.titleColor ?? colors.gray.c10};
        `}
      >
        {section.label}
      </Heading>

      <Grid>
        {section.error && (
          <Cell size={12}>
            <Text>{section.error}</Text>
          </Cell>
        )}

        {section.fields?.map((field, key) => (
          <ValidationErrorsModalItem key={key} item={field} expanded={isFieldsExpanded} />
        ))}
      </Grid>
    </VFlow>
  )
}
