import { Button, Icon, Tooltip } from 'bold-ui'
import React from 'react'

interface MChatModalButtonCloseProps {
  onClose: () => void
}

export function MChatModalButtonClose({ onClose }: MChatModalButtonCloseProps) {
  return (
    <Tooltip text='Fechar'>
      <Button size='small' skin='ghost' onClick={onClose}>
        <Icon icon='timesDefault' />
      </Button>
    </Tooltip>
  )
}
