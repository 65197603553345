import { Text } from 'bold-ui'
import React from 'react'

export function AlertaRetificacaoAtendimentoText() {
  return (
    <Text color='inherit'>
      Todos os registros adicionados, editados e removidos da retificação serão enviados para a base nacional.{' '}
      <Text color='inherit' fontWeight='bold'>
        As alterações poderão ser visualizadas na Trilha de Auditoria do PEC e-SUS APS e impressas através do histórico
        do cidadão.
      </Text>
    </Text>
  )
}
