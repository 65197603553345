import { Button, FormControl, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { CheckboxField, Form, FormPrompt, TextAreaField } from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import { HideOnScreenSize } from 'components/responsive'
import { FormState } from 'final-form'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { ReactComponent as EnviarGarantia } from 'images/cuidado-compartilhado/icon-garantia-acesso.svg'
import React, { Fragment } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { MetaPath, metaPath } from 'util/metaPath'
import {
  CondutaGroupEnum,
  EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel,
  LabelSubtitleGarantiaAcessoRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'

import { agendamentoConsultaCompartilhadaDecorator } from '../../discussao-caso/calculator-discussaoCaso'
import { enviarGarantiaAcessoCuidadoCompartilhadoFieldValidator } from '../../discussao-caso/validator-discussaoCaso'
import { AlertCidadaoNaoAceitouCC } from '../AlertCidadaoNaoAceitouCC'

interface EnviarGarantiaAcessoCuidadoCompartilhadoFieldProps {
  name: MetaPath<EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel>
  pathCondutaGroup: MetaPath<CondutaGroupEnum>
  tipoAgendamento: CondutaCuidadoCompartilhadoEnum
  cidadaoAceitaAtendTic: boolean
  isEdit?: boolean
}

const path = metaPath<EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel>()

export const GARANTIA_ACESSO_CC_URL = '/enviar-garantia'

export function EnviarGarantiaAcessoCuidadoCompartilhadoField(
  props: EnviarGarantiaAcessoCuidadoCompartilhadoFieldProps
) {
  const { name, pathCondutaGroup, tipoAgendamento, cidadaoAceitaAtendTic, isEdit = false } = props

  const history = useHistory()
  const match = useRouteMatch()

  const { videochamadasEnabled } = useVideochamadasConfiguration()

  const {
    input: {
      value: { motivo, isVideochamada, tipoAgendamento: tipoAgendamentoValue },
      onChange,
    },
  } = usePecField({ name, subscription: { value: true } })

  const {
    input: { onChange: onChangeCondutaGroup },
  } = usePecField({ name: pathCondutaGroup, subscription: { value: true } })

  const openModal = () => {
    history.push(`${match.path}${GARANTIA_ACESSO_CC_URL}`)
  }

  const closeModal = () => {
    history.push(match.url.replace(`${GARANTIA_ACESSO_CC_URL}`, ''))
  }

  const handleSubmit = (values) => {
    onChange(values)
    onChangeCondutaGroup(CondutaGroupEnum.ENVIAR_GARANTIA_ACESSO)
  }

  const handleCancel = () => {
    confirm({
      title: 'Deseja cancelar o envio para garantia do acesso?',
      body: 'As alterações realizadas serão perdidas.',
      confirmLabel: 'Sim',
      cancelLabel: 'Não',
      onConfirm: () => {
        onChange(null)
        onChangeCondutaGroup(CondutaGroupEnum.AGENDAR_CONSULTA)
      },
    })()
  }

  const initialValues: EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel = {
    motivo,
    isVideochamada,
    tipoAgendamento: tipoAgendamentoValue ?? tipoAgendamento,
  }

  const onSubmitSucceeded = (formState: FormState<EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel>) => {
    closeModal()
  }

  return (
    <Fragment>
      {!isEdit ? (
        <Fragment>
          <HideOnScreenSize up='lg'>
            <Button type='button' kind='normal' size='small' skin='outline' onClick={openModal}>
              <HFlow hSpacing={0.3} alignItems='center'>
                <EnviarGarantia height={18} />
                Enviar para garantia do acesso
              </HFlow>
            </Button>
          </HideOnScreenSize>
          <HideOnScreenSize down='lg'>
            <FormControl label={<span style={{ userSelect: 'none' }}>&nbsp;</span>}>
              <Button type='button' kind='normal' size='small' skin='outline' onClick={openModal}>
                <HFlow hSpacing={0.3} alignItems='center'>
                  <EnviarGarantia height={18} />
                  Enviar para garantia do acesso
                </HFlow>
              </Button>
            </FormControl>
          </HideOnScreenSize>
        </Fragment>
      ) : (
        <HFlow justifyContent='space-between'>
          <HFlow hSpacing={0.5} justifyContent='flex-end'>
            <Button size='small' onClick={() => handleCancel()}>
              Cancelar e voltar ao agendamento
            </Button>
            <Button size='small' kind='primary' onClick={openModal}>
              <Icon icon='penOutline' height={20} />
              Editar
            </Button>
          </HFlow>
        </HFlow>
      )}

      <Route
        path={`${match.path}${GARANTIA_ACESSO_CC_URL}`}
        render={() => (
          <Form<EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel>
            initialValues={initialValues}
            validate={enviarGarantiaAcessoCuidadoCompartilhadoFieldValidator}
            onSubmit={handleSubmit}
            onSubmitSucceeded={onSubmitSucceeded}
            decorators={agendamentoConsultaCompartilhadaDecorator}
            render={(formProps) => (
              <Modal open onClose={closeModal}>
                <ModalBody>
                  <VFlow vSpacing={1.5}>
                    <FormPrompt />
                    <VFlow vSpacing={0.25}>
                      <Heading level={1}>Enviar para garantia do acesso</Heading>
                      <HFlow hSpacing={0.5}>
                        <Heading level={3} fontWeight='normal'>
                          <strong>Demanda:</strong>{' '}
                          {LabelSubtitleGarantiaAcessoRecord[formProps.values.tipoAgendamento]}
                        </Heading>
                      </HFlow>
                    </VFlow>
                    <TextAreaField
                      style={{ resize: 'none', height: '5rem' }}
                      name={path.motivo}
                      label='Motivo'
                      maxLength={200}
                      required
                    />

                    {videochamadasEnabled &&
                      formProps.values.tipoAgendamento !==
                        CondutaCuidadoCompartilhadoEnum.AGENDADO_CONSULTA_CIDADAO_PRESENCIAL && (
                        <VFlow vSpacing={0.5}>
                          <Text fontWeight='bold'>Sobre a demanda</Text>
                          <CheckboxField
                            name={path.isVideochamada}
                            label={<Text fontWeight='bold'>Consulta será via Videochamada eSUS APS</Text>}
                          />

                          {!cidadaoAceitaAtendTic && <AlertCidadaoNaoAceitouCC />}
                        </VFlow>
                      )}
                  </VFlow>
                </ModalBody>
                <ModalFooter>
                  <HFlow justifyContent='flex-end'>
                    <FooterButton onClick={closeModal}>Cancelar</FooterButton>
                    <FooterButton kind='primary' onClick={formProps.handleSubmit}>
                      Salvar
                    </FooterButton>
                  </HFlow>
                </ModalFooter>
              </Modal>
            )}
          />
        )}
      />
    </Fragment>
  )
}
