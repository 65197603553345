import { resolveLabelKey } from 'components/modals/fields-summary'
import { getIn } from 'final-form'
import { MetaPath } from 'util/metaPath'

import { Label, LabelMap } from './model-fieldsLabels'

export const getFieldLabel = <FieldModel, FormModel>(
  fieldName: MetaPath<FieldModel>,
  fieldsLabels: LabelMap<FormModel>
): string => {
  const resolvedName = resolveLabelKey<FieldModel>(fieldName)
  return getIn(fieldsLabels, resolvedName)?.label
}

export const getFieldLabelGroup = <FieldModel, FormModel>(
  fieldName: MetaPath<FieldModel>,
  fieldsLabels: LabelMap<FormModel>
): Label<FieldModel> => {
  const resolvedName = resolveLabelKey<FieldModel>(fieldName)
  return getIn(fieldsLabels, resolvedName) ?? {}
}
