/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell, Grid, HeadingSection, Theme, useTheme, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { LoadingIndicator } from 'components/loading'
import { useFlags } from 'config/useFlagsContext'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment } from 'react'
import { useField } from 'react-final-form'
import { NIVEL_SUPERIOR } from 'types/NivelCbo'
import { calculateAge } from 'util/date/calculateAge'
import { MetaPath } from 'util/metaPath'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { AlteracaoFenotipicaView } from './alteracao-fenotipica/AlteracaoFenotipicaView'
import PuericulturaAlert from './components/PuericulturaAlert'
import { PuericulturaBox } from './components/PuericulturaBox'
import { FatoresRiscoView } from './fatores-risco/FatoresRiscoView'
import { MarcoFormSectionAccordion } from './marcos/MarcoFormSectionAccordion'
import MarcosDesenvolvimentoView from './marcos/MarcosDesenvolvimentoView'
import { MChatField } from './mchat/MChatField'
import { PuericulturaModel } from './model'
import TipoAleitamentoField from './prenatal-parto-nascimento/components/TipoAleitamentoField'
import PrenatalPartoNascimento from './prenatal-parto-nascimento/PrenatalPartoNascimento'

export interface PuericulturaFieldProps {
  name: MetaPath<PuericulturaModel>
  cidadao: CidadaoAtendimento
  loading: boolean
}

const LIMITE_IDADE_AVALIACAO_DESENVOLVIMENTO = 10

export default function PuericulturaField(props: PuericulturaFieldProps) {
  const { name, cidadao, loading } = props
  const { M_CHAT_ENABLED } = useFlags()
  const theme = useTheme()
  const classes = createStyles(theme)

  const { hasCboAuth } = useSession()
  const {
    atendimentoProfissional: { iniciadoEm: dataAtendimento },
    cidadao: { dataNascimento },
  } = useAtendimentoContext()

  const { years: idadeEmAnos, months: mesesIdade } = calculateAge(dataNascimento, dataAtendimento.getTime())
  const idadeEmMeses = 12 * idadeEmAnos + mesesIdade
  const acessoCriancaEntre16E30Meses = idadeEmMeses >= 16 && idadeEmMeses <= 30
  const acessoMChatValido = M_CHAT_ENABLED && acessoCriancaEntre16E30Meses && hasCboAuth(NIVEL_SUPERIOR)

  const acessoCriancaAte2Anos = idadeEmAnos < 2
  const acessoAvaliarDesenvolvimento = idadeEmAnos < LIMITE_IDADE_AVALIACAO_DESENVOLVIMENTO

  const {
    input: {
      value: {
        hasPuericultura,
        alteracoesFenotipicas,
        marcosDesenvolvimento,
        fatoresRisco,
        medicaoAnteriorPerimetroCefalico,
      },
    },
  } = useField<PuericulturaModel>(name.absolutePath())

  if (loading) return <LoadingIndicator />

  return (
    <PuericulturaBox>
      <VFlow>
        <PrenatalPartoNascimento name={name.prenatalPartoNascimento} hasAtendimentoPuericultura={hasPuericultura} />
        {acessoCriancaAte2Anos && <TipoAleitamentoField name={name.tipoAleitamento} />}
        {acessoMChatValido && <MChatField name={name.mchat} />}
        {acessoAvaliarDesenvolvimento ? (
          <Grid style={classes.border}>
            <Cell size={12}>
              <Fragment>
                <HeadingSection level={3} title='Desenvolvimento da criança' />
                <VFlow>
                  <PuericulturaAlert
                    alteracoesFenotipicas={alteracoesFenotipicas?.alteracoesFenotipicas}
                    fatoresRisco={fatoresRisco?.fatoresRisco}
                    marcosDesenvolvimento={marcosDesenvolvimento}
                    medicaoAnteriorPerimetroCefalico={medicaoAnteriorPerimetroCefalico}
                    cidadao={cidadao}
                    dataReferencia={dataAtendimento.getTime()}
                  />
                  <AlteracaoFenotipicaView name={name.alteracoesFenotipicas} />
                  <FatoresRiscoView name={name.fatoresRisco} />
                  <MarcosDesenvolvimentoView>
                    <MarcoFormSectionAccordion name={name.marcosDesenvolvimento} />
                  </MarcosDesenvolvimentoView>
                </VFlow>
              </Fragment>
            </Cell>
          </Grid>
        ) : (
          <Alert type='info' inline>
            Após os {LIMITE_IDADE_AVALIACAO_DESENVOLVIMENTO} anos de idade, as informações de desenvolvimento da criança
            permanecem somente no acompanhamento da criança.
          </Alert>
        )}
      </VFlow>
    </PuericulturaBox>
  )
}

const createStyles = (theme: Theme) => ({
  border: css`
    border-top: 1px solid ${theme.pallete.divider};
  `,
})
