/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ApolloError } from 'apollo-client'
import { Alert, Heading, VFlow } from 'bold-ui'
import {
  useDataLimiteAlterarSenhaQuery,
  useRecuperarSenhaBloqueadoQuery,
  useSolicitacaoRecuperarSenhaMutation,
} from 'graphql/hooks.generated'
import { useState } from 'react'
import { useHistory } from 'react-router'

import { informationRecuperarSenhaDesabilitado } from './InformationRecuperarSenhaDesabilitado'
import { SolicitarRecuperarSenhaForm } from './SolicitarRecuperarSenhaForm'

export const SolicitarRecuperarSenhaView = () => {
  const [solicitarRecuperarSenha] = useSolicitacaoRecuperarSenhaMutation()
  const { refetch: refetchDataLimite } = useDataLimiteAlterarSenhaQuery()
  const [error, setError] = useState<ApolloError>()
  const history = useHistory()
  const styles = createStyles()

  const { data, refetch } = useRecuperarSenhaBloqueadoQuery()

  const isRecuperarSenhaBloqueado = data?.recuperarSenhaBloqueado ?? false

  const doRedefinirSenha = async (form: SolicitarRecuperarSenhaForm) => {
    const { data: dataLimite } = await refetchDataLimite({ login: form.usuario })

    const dataLimiteAlterarSenha = new Date(dataLimite?.dataLimiteAlterarSenha)

    const podeTrocarSenha = dataLimiteAlterarSenha === undefined || new Date() > dataLimiteAlterarSenha

    if (podeTrocarSenha) {
      solicitarRecuperarSenha({ variables: { input: { ...form } } })
        .then(() => {
          history.push('/?emailEnviado')
        })
        .catch((error) => {
          return refetch().finally(() => {
            setError(error.graphQLErrors[0] || error)
          })
        })
    } else {
      informationRecuperarSenhaDesabilitado(dataLimiteAlterarSenha)
    }
  }

  return (
    <VFlow>
      {error && (
        <Alert styles={{ wrapper: styles.alertWrapper }} type='danger' inline>
          {error.message}
        </Alert>
      )}
      <Heading level={5}>Recuperação de senha</Heading>
      <p>Informe seu usuário para receber um e-mail com instruções para recuperar a sua senha do e-SUS APS PEC.</p>
      <SolicitarRecuperarSenhaForm
        onRequestRedefinicaoSenha={doRedefinirSenha}
        isRecuperarSenhaBloqueado={isRecuperarSenhaBloqueado}
      />
    </VFlow>
  )
}

const createStyles = () => ({
  alertWrapper: css`
    width: 100%;
  `,
})
