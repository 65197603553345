import { HFlow, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'

export function NewsModalBodyLinks() {
  const { ATIVIDADE_COLETIVA_ENABLED } = useFlags()

  return (
    <HFlow hSpacing={2}>
      <VFlow vSpacing={0.2}>
        <Text component='li'>Gráficos de ganho de peso gestacional</Text>
        <Text component='li'>Relatórios de envios com sucesso</Text>
        {ATIVIDADE_COLETIVA_ENABLED && <Text component='li'>Novo módulo de Atividade Coletiva no PEC</Text>}
        <Text component='li'>Atualização dos gráficos de peso e altura para crianças</Text>
        <Text component='li'>Atualização do gráfico de altura para adolescentes</Text>
      </VFlow>
    </HFlow>
  )
}
