/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Table, TableBody, TableHead, TableHeader, TableRow, Theme, useTheme } from 'bold-ui'
import { ModalBody } from 'components/modals/ModalBody'

import { DirtyFieldsModalSection } from './DirtyFieldsModalSection'
import { DirtyFieldModalItemModel } from './model-dirtyFieldsModal'

interface DirtyFieldsModalProps {
  items: DirtyFieldModalItemModel[]
}

export function DirtyFieldsModal(props: DirtyFieldsModalProps) {
  const { items } = props

  const theme = useTheme()
  const classes = createStyles(theme)

  return (
    <ModalBody
      type='alert'
      icon='exclamationTriangleOutline'
      title='Os seguintes campos serão retificados:'
      titleLevel={1}
      style={classes.modalBody}
    >
      <Table style={classes.table}>
        <TableHead>
          <TableRow>
            <TableHeader style={classes.columnSecao}>Seção</TableHeader>
            <TableHeader style={classes.columnCampo}>Campo</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, key) => (
            <DirtyFieldsModalSection section={item} key={key} />
          ))}
        </TableBody>
      </Table>
    </ModalBody>
  )
}

const createStyles = (theme: Theme) => ({
  modalBody: css`
    width: 40rem;

    ${theme.breakpoints.down('lg')} {
      width: 32.5rem;
    }
  `,
  table: css`
    border-spacing: 0 0.5rem;
  `,
  columnSecao: css`
    width: 8rem;
  `,
  columnCampo: css`
    width: 32rem;
  `,
})
