import { Button, ButtonGroup, Cell, Text, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { CategoriaArquivoAtendProfEnum } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'

import { EstadoBotaoExames } from '../model-anexoArquivos'
import { isCategoriaAnexoResultadoExame } from '../utils/utils-anexoArquivos'

interface ArquivoResultadoExameButtonGroupProps {
  categoria: CategoriaArquivoAtendProfEnum
}

export function ArquivoResultadoExameButtonGroup(props: ArquivoResultadoExameButtonGroupProps) {
  const { categoria } = props

  const [estadoBotaoExames, setEstadoBotaoExames] = useState<EstadoBotaoExames>('SOLICITADO')
  const [loadingResultadosExames, setLoadingResultadosExames] = useState<boolean>(false)
  const [showButtons, setShowButtons] = useState<boolean>(false)

  useEffect(() => {
    if (isCategoriaAnexoResultadoExame(categoria)) {
      setLoadingResultadosExames(true)
      setShowButtons(true)
      setTimeout(() => {
        setLoadingResultadosExames(false)
      }, 800)
    } else {
      setShowButtons(false)
    }
  }, [categoria])

  if (loadingResultadosExames) {
    return (
      <Cell size={12}>
        <LoadingIndicator />
      </Cell>
    )
  }

  return showButtons ? (
    <Cell size={12}>
      <VFlow vSpacing={0.2}>
        <Text fontWeight='bold'>Vincular à exames: </Text>
        <ButtonGroup>
          <Button
            kind={estadoBotaoExames === 'SOLICITADO' ? 'primary' : 'normal'}
            size='small'
            skin='default'
            onClick={() => setEstadoBotaoExames('SOLICITADO')}
          >
            Solicitado
          </Button>
          <Button
            kind={estadoBotaoExames === 'NAO_SOLICITADO' ? 'primary' : 'normal'}
            size='small'
            skin='default'
            onClick={() => setEstadoBotaoExames('NAO_SOLICITADO')}
          >
            Não solicitado
          </Button>
        </ButtonGroup>
      </VFlow>
    </Cell>
  ) : null
}
