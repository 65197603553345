import { Text, VFlow } from 'bold-ui'
import { information } from 'components/modals/information'
import React from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'

export const informationRecuperarSenhaDesabilitado = (dataLimiteAlterarSenha: Date) => {
  const dataFormatada = formatDateAndHoursMinutes(dataLimiteAlterarSenha)

  information({
    title: 'Recuperar senha desabilitado',
    titleStyle: { display: 'flex', alignItems: 'center', fontSize: '24px' },
    body: (
      <VFlow>
        <Text>
          A senha desse usuário foi redefinida dentro das últimas 24 horas. Será possível recuperar a senha no dia{' '}
          {dataFormatada}.
        </Text>
      </VFlow>
    ),
    type: 'normal',
    iconColor: 'alert',
    iconType: 'danger',
    showCloseButton: true,
    closeLabel: 'Voltar',
  })()
}
