import { css } from '@emotion/core'
import { Alert, Button, Icon, VFlow } from 'bold-ui'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { PrivateRoute } from 'components/route'
import { useArquivosAnexadosNoProntuarioQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useConfiguracoes } from 'hooks/configuracoes'
import React from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { MetaArray } from 'util/metaPath'

import { grupoCboAnexoArquivos } from '../acessos'
import { AnexoArquivosModal } from './components/AnexoArquivosModal'
import { AnexoArquivosTable } from './components/table/AnexoArquivosTable'
import { AnexoArquivoFormModel } from './model-anexoArquivos'

interface AnexoArquivosViewProps {
  name: MetaArray<AnexoArquivoFormModel>
}

const ANEXO_ARQUIVOS_MODAL_URL = '/anexo-arquivo'

export function AnexoArquivosView(props: AnexoArquivosViewProps) {
  const { name } = props

  const history = useHistory()
  const match = useRouteMatch()

  const openModal = () => {
    history.push(`${match.path}${ANEXO_ARQUIVOS_MODAL_URL}`)
  }

  const closeModal = () => {
    history.push(match.url.replace(`${ANEXO_ARQUIVOS_MODAL_URL}`, ''))
  }

  const {
    configuracoes: { anexoArquivosEnabled },
  } = useConfiguracoes()

  const {
    atendimentoProfissional: {
      id: atendimentoProfissionalId,
      lotacao: { id: lotacaoId, profissional, cbo },
    },
    prontuario: { id: prontuarioId },
  } = useAtendimentoContext()

  const { data, loading } = useArquivosAnexadosNoProntuarioQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      prontuarioId,
    },
  })

  const renderFieldArray = (fieldArrayRenderProps: FieldArrayRenderProps<AnexoArquivoFormModel, any>) => {
    const {
      fields: { push, value, remove, update },
      meta: { invalid },
    } = fieldArrayRenderProps

    return (
      <VFlow>
        {anexoArquivosEnabled && (
          <>
            <Button kind='primary' size='small' data-cy='AnexoArquivos.AnexarArquivo' onClick={openModal}>
              <Icon
                icon='upload'
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              Anexar arquivo
            </Button>
            <PrivateRoute
              cbos={grupoCboAnexoArquivos}
              path={`${match.path}${ANEXO_ARQUIVOS_MODAL_URL}`}
              render={() => <AnexoArquivosModal onClose={closeModal} onConfirm={push} />}
            />
          </>
        )}
        {invalid && (
          <Alert inline type='danger'>
            Existem campos preenchidos de forma incorreta.
          </Alert>
        )}
        {(anexoArquivosEnabled || !data.arquivosAnexadosNoProntuario?.isEmpty()) && (
          <AnexoArquivosTable
            atendimentoProfissionalId={atendimentoProfissionalId}
            lotacaoId={lotacaoId}
            profissional={profissional}
            cbo={cbo}
            arquivosAnexados={value}
            anexoArquivosLoading={loading}
            arquivosAnexadosDatabase={data.arquivosAnexadosNoProntuario}
            onRemoveRow={remove}
            onUpdateRow={update}
          />
        )}
      </VFlow>
    )
  }

  return <FieldArray<AnexoArquivoFormModel> name={resolveName(name)} render={renderFieldArray} />
}
