import { getIn } from 'final-form'
import { MetaPath } from 'util/metaPath'

import { resolveLabelKey } from '../../util-fieldsSummaryModal'
import { ValidationErrorModalConfig, ValidationErrorModalConfigItem } from './model-validationErrorsModal'

export const getFieldLabelConfig = <TFieldModel, TFormModel>(
  fieldName: MetaPath<TFieldModel>,
  config: ValidationErrorModalConfig<TFormModel>
): ValidationErrorModalConfigItem<TFieldModel> => {
  const resolvedName = resolveLabelKey<TFieldModel>(fieldName)
  return getIn(config, resolvedName) ?? {}
}
