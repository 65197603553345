import { FieldStatusEnum } from 'components/form/final-form/components/FieldStatus/model-fieldStatus'
import { getFieldStatus } from 'components/form/final-form/hooks/field-status/getFieldStatus'
import { compact, get, isEqualWith } from 'lodash'
import { LabelMap } from 'util/fields-labels/model-fieldsLabels'
import { getFieldLabelGroup } from 'util/fields-labels/util-fieldsLabels'
import { MetaPath, MetaRoot } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { isEmpty, isRichTextValueEmpty } from 'util/validation/Util'

import { resolveLabelKey } from '../../util-fieldsSummaryModal'
import {
  DirtyFieldModalItemModel,
  DirtyFields,
  DirtyFieldsModalConfig,
  DirtyFieldsModalConfigItem,
  DirtyFieldsObject,
} from './model-dirtyFieldsModal'

export const getDirtyFieldsObject = <FormModel>(
  initialValues: FormModel,
  currentValues: FormModel,
  fieldPaths: string[],
  ignoredFields: string[] = []
): DirtyFieldsObject<FormModel> => {
  const dirtyFieldsStatus = getDirtyFieldsStatus(initialValues, currentValues, fieldPaths, ignoredFields)
  return createDirtyFieldsObject(dirtyFieldsStatus)
}

export const createDirtyFieldsObject = <FormModel>(dirtyFields: DirtyFields): DirtyFieldsObject<FormModel> => {
  const dirtyFieldsObject: DirtyFieldsObject<FormModel> = {}

  for (const [dirtyFieldsPath, value] of Object.entries(dirtyFields)) {
    const paths = dirtyFieldsPath.split('.')
    let current = dirtyFieldsObject

    paths.forEach((path, index) => {
      if (index === paths.length - 1) {
        current[path] = value
      } else {
        if (typeof current[path] !== 'object' || current[path] === null) {
          current[path] = {}
        }
        current = current[path]
      }
    })
  }

  return dirtyFieldsObject
}

export const getDirtyFieldsStatus = <FormModel>(
  initialValues: FormModel,
  currentValues: FormModel,
  fieldPaths: string[],
  ignoredFields: string[]
): DirtyFields =>
  fieldPaths.reduce((acc, fieldPath) => {
    const initial = get(initialValues, fieldPath)
    const current = get(currentValues, fieldPath)

    const dirty = isDirty(initial, current, ignoredFields)

    const status = getFieldStatus(initial, current, typeof current === 'string' ? isRichTextValueEmpty : isEmpty, dirty)

    acc[fieldPath] = status === FieldStatusEnum.NAO_MODIFICADO ? null : true

    return acc
  }, {})

export const isDirty = <FormModel>(initialFormValue: FormModel, currentFormValue: FormModel, ignoredFields: string[]) =>
  !isEqualWith(initialFormValue, currentFormValue, (_initialFieldValue, _currentFieldValue, key) => {
    return ignoredFields.some((ignoredField) => ignoredField === key) ? true : undefined
  })

export function convertDirtyFieldsStatusToModalItems<FormModel, FieldModel extends keyof FormModel>(
  dirtyFieldsStatusObject: DirtyFieldsObject<FormModel>,
  labels: LabelMap<FormModel>,
  config: DirtyFieldsModalConfig<FormModel>,
  metaRoot: MetaRoot<FormModel>,
  parentName: MetaPath<FieldModel> = null
): DirtyFieldModalItemModel[] {
  return (
    dirtyFieldsStatusObject &&
    compact(
      Object.entries(dirtyFieldsStatusObject).map(([key, value]: [string, DirtyFieldsObject<FormModel>]) => {
        if (isObjectDeepEmpty(value)) return null

        const name = parentName ? parentName[key] : metaRoot[key]
        const { label, noFieldsPlaceholder } = getFieldLabelGroup(name, labels)

        if (!label) return null

        const { titleColor, hideLabelOnModal, hideChildrenOnModal, showNoFieldsPlaceholder } = getDirtyFieldLabelConfig(
          name,
          config
        )

        const baseItem = {
          label,
          titleColor,
          showLabelOnModal: !hideLabelOnModal,
          fields: [],
        }

        if (typeof value === 'object') {
          baseItem.fields = !hideChildrenOnModal
            ? convertDirtyFieldsStatusToModalItems(value, labels, config, name)
            : []
        }

        if (showNoFieldsPlaceholder) {
          baseItem.fields = []
          baseItem['noFieldsPlaceholder'] = noFieldsPlaceholder
        }

        return baseItem
      })
    )
  )
}

const getDirtyFieldLabelConfig = <TFieldModel, TFormModel>(
  fieldName: MetaPath<TFieldModel>,
  config: DirtyFieldsModalConfig<TFormModel>
): DirtyFieldsModalConfigItem<TFieldModel> => {
  const resolvedName = resolveLabelKey<TFieldModel>(fieldName)
  return get(config, resolvedName) ?? {}
}
