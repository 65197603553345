import { useAlert } from 'components/alert'
import { CategoriaArquivoAtendProfEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useCallback, useState } from 'react'

import { ANEXO_ARQUIVOS_DATABASE_ERROR_MESSAGE, ANEXO_ARQUIVOS_STORAGE_ERROR_MESSAGE } from '../model-anexoArquivos'
import { visualizarArquivoAnexado } from '../visualizarArquivoAnexado'

interface VisualizarArquivoAnexadoResult {
  handleVisualizarArquivoAnexado: (arquivoId: ID, categoriaAnexo: CategoriaArquivoAtendProfEnum) => void
  loading: boolean
}

export const useVisualizarArquivoAnexado = (): VisualizarArquivoAnexadoResult => {
  const alert = useAlert()
  const { analytics } = useFirebase()
  const [loading, setloading] = useState<boolean>(false)

  const handleVisualizarArquivoAnexado = useCallback(
    (arquivoId: ID, categoriaAnexo: CategoriaArquivoAtendProfEnum) => {
      setloading(true)
      visualizarArquivoAnexado(arquivoId)
        .catch((error) => {
          alert(
            'danger',
            error.response.status === 404 ? ANEXO_ARQUIVOS_DATABASE_ERROR_MESSAGE : ANEXO_ARQUIVOS_STORAGE_ERROR_MESSAGE
          )
        })
        .finally(() => setloading(false))
      analytics.logEvent('visualizar_arquivo_anexado', { categoria: categoriaAnexo })
    },
    [alert, analytics]
  )

  return { handleVisualizarArquivoAnexado, loading }
}
