import { AnexoArquivoInput } from 'graphql/types.generated'

import { AnexoArquivoFormModel, AnexoArquivoRowModel } from './model-anexoArquivos'

export function convertAnexoArquivosToInput(anexoArquivos: AnexoArquivoFormModel[]): AnexoArquivoInput[] {
  return anexoArquivos?.map((anexoArquivo) => ({
    arquivoId: anexoArquivo.arquivoId,
    titulo: anexoArquivo.titulo,
    observacao: anexoArquivo.observacao,
    categoria: anexoArquivo.categoria,
  }))
}

export function convertAnexoArquivoRowToForm(row: AnexoArquivoRowModel): AnexoArquivoFormModel {
  return {
    arquivoId: row.arquivo.id,
    titulo: row.titulo,
    observacao: row.observacao,
    categoria: row.categoria,
    dataCriacao: row.arquivo.dataCriacao,
  }
}
