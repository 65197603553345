/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { isUndefinedOrNull } from 'util/checks'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import {
  ActionEvolucaoCuidadoCompartilhadoRecord,
  ClassificacaoPrioridadeCuidadoRecord,
  CuidadoCompartilhadoEvolucaoModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getResponsabilidadeCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { CuidadoCompartilhadoItemWrapper } from './CuidadoCompartilhadoItemWrapper'

interface CuidadoCompartilhadoEvolucaoItemProps {
  evolucao: CuidadoCompartilhadoEvolucaoModel
  isFirstEvolucao: boolean
  isLastEvolucao: boolean
}

export const CuidadoCompartilhadoEvolucaoItem = (props: CuidadoCompartilhadoEvolucaoItemProps) => {
  const { evolucao, isFirstEvolucao, isLastEvolucao } = props

  const isExecutante =
    getResponsabilidadeCuidadoCompartilhado(
      evolucao.lotacao.id,
      evolucao.lotacaoExecutante?.id,
      evolucao.cuidadoCompartilhado.lotacaoSolicitante.id
    ) === 'EXECUTANTE'

  const {
    Icon,
    backgroundColor,
    getTitle,
    subtitleLabel,
    getSubtitle,
    descriptionLabel,
    getDescription,
    getBottomTextLabel,
    getBottomText,
  } = ActionEvolucaoCuidadoCompartilhadoRecord[evolucao.conduta]
  const subtitle = getSubtitle?.(evolucao)
  const descriptionSanitized = getDescription(evolucao).removeTagsNonRichText()
  const bottomTextLabel = getBottomTextLabel?.(evolucao)
  const bottomText = getBottomText?.(evolucao)

  const theme = useTheme()
  const styles = createStyles(theme, isFirstEvolucao, isLastEvolucao)

  return (
    <CuidadoCompartilhadoItemWrapper
      Icon={Icon}
      backgroundColorWrapper={backgroundColor}
      backgroundColorIcon={isExecutante ? theme.pallete.gray.c40 : theme.pallete.primary.c40}
      isFirstItem={isFirstEvolucao}
      isLastItem={isLastEvolucao}
    >
      <VFlow vSpacing={0.5} style={styles.contentColumn}>
        <VFlow vSpacing={0}>
          <HFlow hSpacing={0.25} alignItems='center'>
            {getTitle(evolucao, isFirstEvolucao)}|{' '}
            <Text color='secondary'>
              <DateTime value={evolucao.dataEvolucao} />
            </Text>
          </HFlow>
          <Text>
            {subtitleLabel && subtitle && <Text fontWeight='bold'>{subtitleLabel}: </Text>}
            {subtitle && <Text>{subtitle}</Text>}
          </Text>
          <Text>
            {descriptionLabel && !isUndefinedOrNull(descriptionSanitized) && !isFirstEvolucao && (
              <Text fontWeight='bold'>{descriptionLabel}: </Text>
            )}
            {descriptionSanitized && (
              <Text dangerouslySetInnerHTML={{ __html: descriptionSanitized }} style={styles.richText} />
            )}
          </Text>
          <Text>
            {bottomTextLabel && bottomText && <Text fontWeight='bold'>{bottomTextLabel}: </Text>}
            {bottomText && <Text style={styles.bottomText}>{bottomText}</Text>}
          </Text>
        </VFlow>
        {isFirstEvolucao && (
          <HFlow hSpacing={0.5}>
            <Text fontWeight='bold'>Classificação de prioridade inicial</Text>
            <StatusSquare
              color={ClassificacaoPrioridadeCuidadoRecord[evolucao.classificacaoPrioridade].cor}
              description={ClassificacaoPrioridadeCuidadoRecord[evolucao.classificacaoPrioridade].descricao}
            />
          </HFlow>
        )}
        {!isFirstEvolucao && !isUndefinedOrNull(evolucao.reclassificacaoPrioridade) && (
          <HFlow hSpacing={0.5}>
            <Text fontWeight='bold'>Reclassificação de prioridade</Text>
            <StatusSquare
              color={ClassificacaoPrioridadeCuidadoRecord[evolucao.reclassificacaoPrioridade].cor}
              description={ClassificacaoPrioridadeCuidadoRecord[evolucao.reclassificacaoPrioridade].descricao}
            />
          </HFlow>
        )}
      </VFlow>
    </CuidadoCompartilhadoItemWrapper>
  )
}

const createStyles = (theme: Theme, isFirstItem: boolean, isLastItem: boolean) => ({
  contentColumn: css`
    padding: 0.5rem 0;
    padding-top: ${isFirstItem ? '0' : '0.5rem'};
    padding-bottom: ${isLastItem ? '0' : '0.5rem'};
  `,
  richText: css`
    color: ${theme.pallete.gray.c20};
    word-break: break-all;

    & p:first-child {
      display: inline;
    }
  `,
  bottomText: css`
    word-break: break-word;
  `,
})
