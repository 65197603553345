import { QueryLazyOptions } from '@apollo/react-hooks'
import { Button, Tooltip } from 'bold-ui'
import { usePecField } from 'components/form'
import { IsDiretorioAnexoArquivosValidoQueryVariables } from 'graphql/types.generated'
import React from 'react'
import { Meta } from 'util/metaPath'

interface DiretorioAnexoArquivosTestButtonProps {
  name: Meta<string>
  isTestLoading: boolean
  executeQueryTestDiretorio: (options?: QueryLazyOptions<IsDiretorioAnexoArquivosValidoQueryVariables>) => void
}

export function DiretorioAnexoArquivosTestButton(props: DiretorioAnexoArquivosTestButtonProps) {
  const { name, isTestLoading, executeQueryTestDiretorio } = props

  const {
    input: { value: diretorioFormValue },
  } = usePecField({
    name,
  })

  const handleTestClick = (dir: string) => {
    executeQueryTestDiretorio({ variables: { diretorio: dir.trim() } })
  }

  return (
    <Tooltip text='Verificar se o diretório de destino dos arquivos existe e está acessível.'>
      <Button
        kind='normal'
        size='small'
        onClick={() => handleTestClick(diretorioFormValue)}
        loading={isTestLoading}
        disabled={!diretorioFormValue?.trim()}
      >
        Testar
      </Button>
    </Tooltip>
  )
}
