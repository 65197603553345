/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useMemo } from 'react'
import { calculateAge } from 'util/date/calculateAge'

import { MCHAT_RESULTADOS, MChatFieldModel, MChatRiscoEnum } from '../model-mchat'
import { MChatResultadoLine } from './MChatResultadoLine'

interface MChatResultadoViewProps {
  values: MChatFieldModel
  isInsideModal?: boolean
}

export function MChatResultadoView(props: MChatResultadoViewProps) {
  const {
    values: { resultado, risco },
    isInsideModal,
  } = props

  const {
    atendimentoProfissional: { iniciadoEm: dataAtendimento },
    cidadao: { dataNascimento },
  } = useAtendimentoContext()

  const { years, months } = calculateAge(dataNascimento, dataAtendimento.getTime())
  const idadeEmMeses = 12 * years + months

  const title = 'Risco ' + MCHAT_RESULTADOS[risco].shortTitle
  const isRiscoMedio = risco === MChatRiscoEnum.MEDIO

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, risco), [risco, theme])

  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={isInsideModal ? 2 : 3} style={styles.title}>
          <strong>{title}</strong> | {resultado} {resultado === 1 ? 'ponto' : 'pontos'}
        </Heading>
      </VFlow>
      <VFlow vSpacing={0.5}>
        <HFlow hSpacing={0.25} style={styles.table}>
          <MChatResultadoLine line={MChatRiscoEnum.BAIXO} pontuacao={resultado} riscoResultado={risco} />
          <MChatResultadoLine line={MChatRiscoEnum.MEDIO} pontuacao={resultado} riscoResultado={risco} />
          <MChatResultadoLine line={MChatRiscoEnum.ELEVADO} pontuacao={resultado} riscoResultado={risco} />
        </HFlow>
        <HFlow hSpacing={0} style={styles.subtitle}>
          <Heading level={5} style={styles.subtitleText}>
            0
          </Heading>
          <Heading level={5} style={styles.subtitleText}>
            3
          </Heading>
          <Heading level={5} style={styles.subtitleText}>
            8
          </Heading>
          <Heading level={5} style={styles.subtitleText}>
            20
          </Heading>
        </HFlow>
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Text fontWeight='bold'>Encaminhamento</Text>
        <PageContainer style={styles.alertContainer}>
          <HFlow style={styles.alertHeader} alignItems='center' hSpacing={0.5}>
            <Icon
              icon={isRiscoMedio ? 'exclamationTriangleFilled' : 'infoCircleFilled'}
              size={1}
              style={styles.title}
            />
            <Text
              dangerouslySetInnerHTML={{ __html: MCHAT_RESULTADOS[risco].encaminhamento(idadeEmMeses) }}
              style={styles.title}
            />
          </HFlow>
          {isInsideModal && isRiscoMedio && (
            <HFlow>
              <VFlow vSpacing={1} style={styles.consultaSeguimento}>
                <Text fontWeight='bold'>Consulta de seguimento</Text>
                Se a pontuação permanecer maior ou igual a 2, a triagem da criança foi positiva. Deve-se encaminhar a
                criança para avaliação diagnóstica e de intervenção precoce.
              </VFlow>
            </HFlow>
          )}
        </PageContainer>
      </VFlow>
    </VFlow>
  )
}

const createStyles = (theme: Theme, risco: MChatRiscoEnum) => ({
  title: css`
    font-weight: normal;
    color: ${MCHAT_RESULTADOS[risco].textColor};
  `,
  icons: css`
    margin-right: 0.5rem;
  `,
  voltarButton: css`
    margin-bottom: 1rem;
  `,
  imprimirButton: css`
    color: ${theme.pallete.primary.main};
    border-color: ${theme.pallete.primary.main};
  `,
  table: css`
    grid-auto-columns: 17fr 25fr 58fr;
    position: relative;
  `,
  subtitle: css`
    grid-gap: 0;
    grid-auto-columns: 17% 25% calc(58% - 1rem) 1%;
  `,
  subtitleText: css`
    color: ${theme.pallete.gray.c40};
  `,
  alertContainer: css`
    padding: 0;
    margin-top: 0;
    outline: 1px solid ${MCHAT_RESULTADOS[risco].lineColor};
  `,
  alertHeader: css`
    padding: 0.5rem;
    background-color: ${MCHAT_RESULTADOS[risco].backgroundColor};
    color: ${MCHAT_RESULTADOS[risco].textColor};
  `,
  consultaSeguimento: css`
    margin: 1rem;
  `,
})
