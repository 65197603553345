import { Cell, Grid, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { VisualizarArquivoAnexadoHistoricoLinkButton } from 'view/atendimentos/detail/soap/objetivo/anexo-arquivos/components/VisualizarArquivoAnexadoHistoricoLinkButton'
import {
  ArquivoAtendimentoProfissionalHistorico,
  categoriaAnexoLabelRecord,
} from 'view/atendimentos/detail/soap/objetivo/anexo-arquivos/model-anexoArquivos'

import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoArquivosAnexadosTableBoxProps {
  arquivosAnexados: ArquivoAtendimentoProfissionalHistorico[]
}

export default function HistoricoArquivosAnexadosTableBox(props: HistoricoArquivosAnexadosTableBoxProps) {
  const { arquivosAnexados } = props

  return (
    <HistoricoDetailTable title='Anexo de arquivos' sectionStyle='O' hasLines>
      <VFlow>
        {arquivosAnexados.map((anexoArquivo) => (
          <Grid key={anexoArquivo.id}>
            <Cell size={6}>
              <InfoLabel title='Título do arquivo'>{anexoArquivo.titulo}</InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Categoria do arquivo'>{categoriaAnexoLabelRecord[anexoArquivo.categoria]}</InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Visualizar arquivo'>
                <VisualizarArquivoAnexadoHistoricoLinkButton arquivoAnexado={anexoArquivo} />
              </InfoLabel>
            </Cell>
            {anexoArquivo.observacao && (
              <Cell size={12}>
                <HLabel title='Observações:'>
                  <Text>{anexoArquivo.observacao}</Text>
                </HLabel>
              </Cell>
            )}
          </Grid>
        ))}
      </VFlow>
    </HistoricoDetailTable>
  )
}
