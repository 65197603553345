/** @jsx jsx */
import { jsx } from '@emotion/core'
import { modal } from 'bold-ui'
import { ModalAutoRenderProps } from 'bold-ui/lib/components/Modal/auto/ModalAuto'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { ModalBody } from 'components/modals/ModalBody'

import { AlterarSenhaForm, AlterarSenhaFormProps } from './AlterarSenhaForm'

interface AlterarSenhaModalFormProps
  extends Omit<AlterarSenhaFormProps, 'onCancelar' | 'afterSubmit' | 'supressAlertError'> {
  title?: string
}

export const alterarSenhaModalForm = (props: AlterarSenhaModalFormProps) => {
  const { title = 'Redefinir senha', ...alterarSenhaProps } = props

  return () =>
    new Promise<boolean>((resolve) => {
      let promiseReturn = false
      const handleResolve = (confirm: boolean) => () => (promiseReturn = confirm)
      const handleClose = () => resolve(promiseReturn)

      modal({
        size: 'large',
        closeOnBackdropClick: false,
        render: ({ close }: ModalAutoRenderProps) => (
          <ModalBody title={title} icon='infoCircleOutline' type='primary'>
            <AlterarSenhaForm
              {...alterarSenhaProps}
              onSubmitSucceeded={composeHandlers(handleResolve(true), close)}
              onCancelar={composeHandlers(handleResolve(false), close)}
              supressAlertError
              isSenhaProvisoria
            />
          </ModalBody>
        ),
        onClose: handleClose,
      })()
    })
}
