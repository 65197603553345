import { AnexoArquivoRowModel } from '../model-anexoArquivos'

export type SortAnexoArquivosField = 'data' | '-data'

function sortAnexoArquivosByData(item0Data: Instant, item1Data: Instant, reverse: boolean): number {
  return reverse ? item1Data - item0Data : item0Data - item1Data
}

const sortAnexoArquivosOptions: Record<
  string,
  (item0: AnexoArquivoRowModel, item1: AnexoArquivoRowModel, reverse: boolean) => number
> = {
  data: (item0: AnexoArquivoRowModel, item1: AnexoArquivoRowModel, reverse: boolean) => {
    return sortAnexoArquivosByData(item0.arquivo.dataCriacao, item1.arquivo.dataCriacao, reverse)
  },
}

// TODO (Legacy) #23588
export function sortAnexoArquivos(
  anexoArquivos: ReadonlyArray<AnexoArquivoRowModel>,
  sort: ReadonlyArray<SortAnexoArquivosField> = []
): AnexoArquivoRowModel[] {
  if (sort.length === 0) {
    return [...anexoArquivos]
  }

  const sortField: SortAnexoArquivosField = sort[0]
  const isDescending: boolean = sortField.startsWith('-')
  const fieldName: string = isDescending ? sortField.substring(1) : sortField

  const anexoArquivosSorted = [...anexoArquivos].sort((item0, item1) =>
    sortAnexoArquivosOptions[fieldName](item0, item1, isDescending)
  )

  return anexoArquivosSorted
}
