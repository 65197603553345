import { metaPath } from 'util/metaPath'

export const VALIDATION_ERROR_CONFIG_ANEXO_ARQUIVOS_MSG = 'O diretório informado não existe ou não está acessível.'

export interface ConfiguracaoAnexoArquivosModel {
  diretorio: string
}

export const CONFIG_ANEXO_ARQUIVOS_META_PATH = metaPath<ConfiguracaoAnexoArquivosModel>()

export const VERIFICACAO_SUCESSO_CONFIG_ANEXO_ARQUIVOS_MSG =
  'Verificação realizada com sucesso. O diretório informado foi encontrado e está acessível.'
