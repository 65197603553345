/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Icon, Spinner, Theme, Tooltip, useTheme } from 'bold-ui'

import { useVisualizarArquivoAnexado } from '../hooks/useVisualizarArquivoAnexado'
import { AnexoArquivoRowModel } from '../model-anexoArquivos'

interface VisualizarArquivoAnexadoTableButtonProps {
  arquivoAnexado: AnexoArquivoRowModel
}

export function VisualizarArquivoAnexadoTableButton(props: VisualizarArquivoAnexadoTableButtonProps) {
  const { arquivoAnexado } = props
  const { handleVisualizarArquivoAnexado, loading } = useVisualizarArquivoAnexado()
  const theme = useTheme()
  const styles = createStyles(theme)

  const handleVisualization = (e: Event) => {
    e.stopPropagation()
    handleVisualizarArquivoAnexado(arquivoAnexado.arquivo.id, arquivoAnexado.categoria)
  }

  return loading ? (
    <Spinner size={1.3} style={styles.spinner} />
  ) : (
    <Tooltip text='Visualizar arquivo'>
      <Button size='small' kind='normal' skin='ghost' onClick={(e) => handleVisualization(e)}>
        <Icon icon='fileVisualizationOutline' />
      </Button>
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  spinner: css`
    color: ${theme.pallete.primary.main};
    padding: 0.35rem 0.53rem;
    display: flex;
    align-items: center;
    margin-right: 0.16rem;
  `,
})
