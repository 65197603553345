/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { FooterButton } from 'components/footer-button'
import { Form, FormPrompt } from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { FormState } from 'final-form'
import { ClassificacaoPrioridadeCuidadoEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { MetaPath, metaPath } from 'util/metaPath'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import { ClassificacaoPrioridadeField } from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/components/ClassificacaoPrioridadeField'
import {
  ClassificacaoPrioridadeCuidadoRecord,
  ReclassificarPrioridadeFormModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { isReclassificandoCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { reclassificacaoPrioridadeValidator } from '../../discussao-caso/validator-discussaoCaso'

interface ReclassificarPrioridadeFieldProps {
  name: MetaPath<ClassificacaoPrioridadeCuidadoEnum>
  prioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
}

const path = metaPath<ReclassificarPrioridadeFormModel>()

const MODAL_URL = 'reclassificar-prioridade'

export function ReclassificarPrioridadeField(props: ReclassificarPrioridadeFieldProps) {
  const { name, prioridadeAtual } = props

  const alert = useAlert()
  const history = useHistory()
  const match = useRouteMatch()

  const {
    input: { value: prioridadeReclassificada, onChange },
  } = usePecField({ name, subscription: { value: true } })

  const openModal = () => {
    history.push(`${match.path}/${MODAL_URL}`)
  }

  const closeModal = () => {
    history.push(match.url.replace(`/${MODAL_URL}`, ''))
  }

  const initialValues: ReclassificarPrioridadeFormModel = { prioridade: prioridadeReclassificada ?? prioridadeAtual }

  const isReclassificando = isReclassificandoCuidadoCompartilhado(prioridadeAtual, prioridadeReclassificada)

  const onSubmitSucceeded = (formState: FormState<ReclassificarPrioridadeFormModel>) => {
    if (formState.values.prioridade !== prioridadeAtual) {
      alert('success', 'Classificação de prioridade alterada com sucesso.')
    } else {
      alert('info', 'Classificação de prioridade dessa discussão foi mantida.')
    }

    closeModal()
  }

  return (
    <Fragment>
      <Box>
        <HFlow alignItems='center' justifyContent='space-between'>
          <VFlow vSpacing={0.25}>
            <HFlow hSpacing={0.5} alignItems='center'>
              <Heading level={4}>Classificação de prioridade</Heading>
              {isReclassificando && (
                <Tooltip text='Adicionado agora'>
                  <Icon size={1} icon='clockOutline' fill='primary' />
                </Tooltip>
              )}
            </HFlow>

            <HFlow style={styles.hFlow}>
              <StatusSquare
                color={ClassificacaoPrioridadeCuidadoRecord[prioridadeAtual].cor}
                description={ClassificacaoPrioridadeCuidadoRecord[prioridadeAtual].descricao}
              />

              {isReclassificando && (
                <HFlow>
                  <Icon icon='arrowRight' />

                  <StatusSquare
                    color={ClassificacaoPrioridadeCuidadoRecord[prioridadeReclassificada].cor}
                    description={ClassificacaoPrioridadeCuidadoRecord[prioridadeReclassificada].descricao}
                  />
                </HFlow>
              )}
            </HFlow>
          </VFlow>

          <Button kind='primary' size='small' onClick={openModal}>
            Reclassificar
          </Button>
        </HFlow>
      </Box>

      <Route
        path={`${match.path}/${MODAL_URL}`}
        render={() => (
          <Form<ReclassificarPrioridadeFormModel>
            initialValues={initialValues}
            onSubmit={(values) => onChange(values.prioridade)}
            onSubmitSucceeded={onSubmitSucceeded}
            validate={reclassificacaoPrioridadeValidator}
            render={(formProps) => (
              <Modal open onClose={closeModal}>
                <ModalBody>
                  <VFlow>
                    <FormPrompt />
                    <Heading level={1}>Reclassificar prioridade do cuidado compartilhado</Heading>

                    <VFlow vSpacing={0}>
                      <Text fontWeight='bold'>Classificação de prioridade atual</Text>

                      <HFlow style={styles.hFlow}>
                        <StatusSquare
                          color={ClassificacaoPrioridadeCuidadoRecord[prioridadeAtual].cor}
                          description={ClassificacaoPrioridadeCuidadoRecord[prioridadeAtual].descricao}
                        />

                        {isReclassificandoCuidadoCompartilhado(prioridadeAtual, formProps.values.prioridade) && (
                          <HFlow>
                            <Icon icon='arrowRight' />

                            <StatusSquare
                              color={ClassificacaoPrioridadeCuidadoRecord[formProps.values.prioridade].cor}
                              description={ClassificacaoPrioridadeCuidadoRecord[formProps.values.prioridade].descricao}
                            />
                          </HFlow>
                        )}
                      </HFlow>
                    </VFlow>

                    <ClassificacaoPrioridadeField name={path.prioridade} required />
                  </VFlow>
                </ModalBody>
                <ModalFooter>
                  <HFlow justifyContent='flex-end'>
                    <FooterButton onClick={closeModal}>Cancelar</FooterButton>
                    <FooterButton kind='primary' onClick={formProps.handleSubmit}>
                      Salvar
                    </FooterButton>
                  </HFlow>
                </ModalFooter>
              </Modal>
            )}
          />
        )}
      />
    </Fragment>
  )
}

const styles = {
  hFlow: css`
    height: 1.5rem;
  `,
}
