import { DateRange, HFlow, Icon, Icons, Text } from 'bold-ui'
import { blue, ColorScale, gray, green, orange, red, yellow } from 'bold-ui/lib/styles/colors'
import { UnidadeSaudeSelectModel } from 'components/form'
import { CboSelectModel } from 'components/form/field/select/CboSelectField/CboSelectField'
import { InfoIcon } from 'components/InfoIcon'
import theme from 'config/theme'
import { Color } from 'csstype'
import {
  ClassificacaoPrioridadeCuidadoEnum,
  CondutaCuidadoCompartilhadoEnum,
  CuidadoCompartilhadoEvolucoesQuery,
  CuidadoCompartilhadoFinalizacoesQuery,
  CuidadoCompartilhadoQuery,
  ListaCuidadoCompartilhadoQuery,
  PageParams,
  SituacaoAgendadoEnum,
  StatusCuidadoCompartilhadoEnum,
} from 'graphql/types.generated'
import { ReactComponent as IconEscutaInicial } from 'images/cuidado-compartilhado/icon-escuta-inicial.svg'
import { ReactComponent as IconHighFiveFilled } from 'images/cuidado-compartilhado/icon-high-five-filled.svg'
import React, { FunctionComponent } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { ValidatorFunction } from 'util/validation/validator'
import { AgendamentoConsultaCompartilhadaModel, AgendamentoConsultaModel } from 'view/agenda/model-agenda'
import { CidadaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacoesCuidadoCompartilhadoSelectModel } from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/components/LotacoesCuidadoCompartilhadoSelectField'
import {
  EquipeCuidadoCompartilhadoSelectFieldQueryModel,
  ProfissionalCuidadoCompartilhadoSelectFieldModel,
} from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/model-cuidadocompartilhado'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import {
  agendarConsultaCuidadoCompartilhadoFieldValidator,
  devolutivaCuidadoCompartilhadoFieldValidator,
  enviarGarantiaAcessoCuidadoCompartilhadoFieldValidator,
  perguntaCuidadoCompartilhadoFieldValidator,
  sugestaoAgendamentoGrupoCuidadoCompartilhadoFieldValidator,
  trocaExecutanteCuidadoCompartilhadoFieldValidator,
} from './cuidado-compartilhado/discussao-caso/validator-discussaoCaso'

export type CuidadoCompartilhadoItem = ListaCuidadoCompartilhadoQuery['cuidadosCompartilhado']['content'][0]

export type CuidadoCompartilhadoItemActions = ListaCuidadoCompartilhadoQuery['cuidadosCompartilhado']['content'][0]['actions']

export type CuidadoCompartilhadoListingQueryPayload = ListaCuidadoCompartilhadoQuery['cuidadosCompartilhado']

export interface ListaCuidadoCompartilhadoInlineFilterModel {
  query?: string
  switch?: boolean
}

export type ListaCuidadoCompartilhadoFilterPopperFormModel = {
  status?: StatusCuidadoCompartilhadoEnum[]
  periodo?: DateRange
  profissionalExecutante?: ProfissionalCuidadoCompartilhadoSelectFieldModel
  profissionalSolicitante?: ProfissionalCuidadoCompartilhadoSelectFieldModel
  unidadeSaude?: UnidadeSaudeSelectModel
  equipe?: EquipeCuidadoCompartilhadoSelectFieldQueryModel[]
  cbo?: CboSelectModel
}

export type ListaCuidadoCompartilhadoFilterModel = ListaCuidadoCompartilhadoInlineFilterModel &
  ListaCuidadoCompartilhadoFilterPopperFormModel & {
    pageParams?: Omit<PageParams, 'sort'> & { sort?: CuidadoCompartilhadoSortEnum }
  }

export type CuidadoCompartilhadoModel = CuidadoCompartilhadoQuery['cuidadoCompartilhado']

export type CuidadoCompartilhadoLotacaoExecutanteModel = CuidadoCompartilhadoModel['lotacaoExecutanteAtual']
export type CuidadoCompartilhadoLotacaoSolicitanteModel = CuidadoCompartilhadoModel['lotacaoSolicitante']

export type CuidadoCompartilhadoLotacaoModel =
  | CuidadoCompartilhadoLotacaoExecutanteModel
  | CuidadoCompartilhadoLotacaoSolicitanteModel

export type CuidadoCompartilhadoEvolucaoQueryModel = CuidadoCompartilhadoEvolucoesQuery['cuidadoCompartilhadoEvolucoes']['content'][0]

export type CuidadoCompartilhadoFinalizacaoQueryModel = CuidadoCompartilhadoFinalizacoesQuery['cuidadoCompartilhadoFinalizacoes']['content'][0]

export type CuidadoCompartilhadoFinalizacaoModel = CuidadoCompartilhadoFinalizacaoQueryModel & {
  type: 'finalizacao'
  data: Instant
}

export type CuidadoCompartilhadoEvolucaoModel = CuidadoCompartilhadoEvolucaoQueryModel & {
  type: 'evolucao'
  data: Instant
  sequenciaEvolucao: number
}

export type CuidadoCompartilhadoEvolucaoMerged =
  | CuidadoCompartilhadoEvolucaoModel
  | CuidadoCompartilhadoFinalizacaoModel

export type ProntuarioCuidadoCompartilhadoModel = CuidadoCompartilhadoModel['cidadao']['prontuario']

export type CidadaoCuidadoCompartilhado = (CidadaoAtendimento | CidadaoFolhaRosto) & {
  isGestante: boolean
  prontuario?: ProntuarioCuidadoCompartilhadoModel
}

export interface DiscussaoCasoFormModel {
  lastSaved?: Instant
  executanteFields?: DiscussaoCasoExecutanteFieldsModel
  solicitanteFields?: DiscussaoCasoSolicitanteFieldsModel
  reclassificaoPrioridade?: ClassificacaoPrioridadeCuidadoEnum
}

export type DiscussaoCasoExecutanteFieldsModel = {
  devolutiva?: DevolutivaCuidadoCompartilhadoFieldsModel
  sugestaoAgendamentoGrupo?: SugestaoAgendamentoGrupoCuidadoCompartilhadoFieldsModel
} & DiscussaoCasoFieldsModel

export type DiscussaoCasoSolicitanteFieldsModel = {
  pergunta?: PerguntaCuidadoCompartilhadoFieldsModel
} & DiscussaoCasoFieldsModel

export interface DiscussaoCasoFieldsModel {
  condutaGroup: CondutaGroupEnum
  trocaExecutante?: TrocaExecutanteCuidadoCompartilhadoFieldsModel
  agendamento?: AgendarConsultaCuidadoCompartilhadoFieldsModel
  garantiaAcesso?: EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel
}

export interface DevolutivaCuidadoCompartilhadoFieldsModel {
  conduta: CondutaCuidadoCompartilhadoEnum
  resposta: string
}

export interface PerguntaCuidadoCompartilhadoFieldsModel {
  pergunta: string
}

export interface TrocaExecutanteCuidadoCompartilhadoFieldsModel {
  cbo: CboSelectModel
  lotacao: LotacoesCuidadoCompartilhadoSelectModel
  motivo: string
}

export interface EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel {
  tipoAgendamento: CondutaCuidadoCompartilhadoEnum
  motivo: string
  isVideochamada: boolean
}

export enum TipoAgendamentoCuidadoCompartilhado {
  CONSULTA_COM_CIDADAO = 'CONSULTA_COM_CIDADAO',
  CONSULTA_COMPARTILHADA = 'CONSULTA_COMPARTILHADA',
}

export interface AgendarConsultaCuidadoCompartilhadoFieldsModel {
  tipoAgendamento: TipoAgendamentoCuidadoCompartilhado
  consultaComCidadao: Pick<AgendamentoConsultaModel, 'data' | 'horario' | 'observacoes'>
  consultaCompartilhada: Omit<AgendamentoConsultaCompartilhadaModel, 'lotacaoConvidada'> & {
    isCidadaoPresente?: boolean
  }
}

export interface ReclassificarPrioridadeFormModel {
  prioridade: ClassificacaoPrioridadeCuidadoEnum
}

export type CuidadoCompartilhadoVideochamadaState = [string, (value: string) => void]

export interface SugestaoAgendamentoGrupoCuidadoCompartilhadoFieldsModel {
  observacao: string
}

export enum CondutaGroupEnum {
  DEVOLUTIVA_DISCUSSAO_CASO = 'DEVOLUTIVA_DISCUSSAO_CASO',
  NOVA_PERGUNTA = 'NOVA_PERGUNTA',
  TROCA_PROFISSIONAL_REFERENCIA = 'TROCA_PROFISSIONAL_REFERENCIA',
  AGENDAR_CONSULTA = 'AGENDAR_CONSULTA',
  SUGESTAO_AGENDAMENTO_GRUPO = 'SUGESTAO_AGENDAMENTO_GRUPO',
  ENVIAR_GARANTIA_ACESSO = 'ENVIAR_GARANTIA_ACESSO',
}

export const ClassificacaoPrioridadeCuidadoRecord: Record<
  ClassificacaoPrioridadeCuidadoEnum,
  { descricao: string; descricaoEstendida?: string; cor?: Color }
> = {
  BAIXA: { descricao: 'Baixa', descricaoEstendida: 'Prioridade baixa', cor: blue.c40 },
  MEDIA: { descricao: 'Média', descricaoEstendida: 'Prioridade média', cor: green.c40 },
  ALTA: { descricao: 'Alta', descricaoEstendida: 'Prioridade alta', cor: yellow.c60 },
  MUITO_ALTA: { descricao: 'Muito alta', descricaoEstendida: 'Prioridade muito alta', cor: red.c40 },
}

export const StatusCuidadoRecord: Record<
  StatusCuidadoCompartilhadoEnum,
  { descricao: string; cor: ColorScale; icon: Icons }
> = {
  AGUARDANDO: { descricao: 'Aguardando', cor: orange, icon: 'exclamationTriangleFilled' },
  RESPONDIDO: { descricao: 'Respondido', cor: green, icon: 'checkCircleFilled' },
  FINALIZADO_OBITO: { descricao: 'Finalizado: óbito', cor: gray, icon: 'checkCircleFilled' },
}

export type ResponsavelCuidadoCompartilhado = 'EXECUTANTE' | 'SOLICITANTE'

export const LabelEvolucaoCuidadoCompartilhadoRecord: Record<CondutaCuidadoCompartilhadoEnum, string> = {
  NOVA_PERGUNTA: 'Discussão de caso clínico',
  PEDIDO_ESCLARECIMENTO: 'Pedido de esclarecimento',
  TELECONSULTORIA: 'Teleconsultoria',
  PARECER_TECNICO_CONCLUSIVO: 'Parecer técnico conclusivo',
  TROCA_PROFISSIONAL_REFERENCIA: 'Trocar profissional de referência',
  RECONDUCAO_CUIDADO: 'Recondução de cuidado',
  AGENDADO_CONSULTA_CIDADAO_PRESENCIAL: 'Consulta com cidadão',
  AGENDADO_INTERCONSULTA_SEM_CIDADAO: 'Agendamento consulta entre profissionais sem o cidadão',
  AGENDADO_INTERCONSULTA_COM_CIDADAO: 'Agendamento consulta entre profissionais com o cidadão',
  AGENDADO_TELEINTERCONSULTA_COM_CIDADAO: 'Agendamento consulta entre profissionais com o cidadão via videochamada',
  AGENDADO_TELEINTERCONSULTA_SEM_CIDADAO: 'Agendamento consulta entre profissionais sem o cidadão via videochamada',
  SUGESTAO_AGENDAMENTO_GRUPO: 'Sugerir agendamento para grupos',
  ENVIAR_GARANTIA_ACESSO: 'Enviar para garantia de acesso',
}

// Os seguintes pares do "LabelCondutaCuidadoCompartilhadoDwRecord" não devem ser removidos e somente adicionados.
// Pois os valores armazenados nas tabelas do histórico são em string e se alguma frase for alterada, valores antigos estarão no formato antigo.
export const LabelCondutaCuidadoCompartilhadoDwRecord: Record<string, CondutaCuidadoCompartilhadoEnum> = {
  'Nova pergunta': CondutaCuidadoCompartilhadoEnum.NOVA_PERGUNTA,
  'Pedido de esclarecimento': CondutaCuidadoCompartilhadoEnum.PEDIDO_ESCLARECIMENTO,
  Teleconsultoria: CondutaCuidadoCompartilhadoEnum.TELECONSULTORIA,
  'Parecer técnico conclusivo': CondutaCuidadoCompartilhadoEnum.PARECER_TECNICO_CONCLUSIVO,
  'Troca de profissional referência': CondutaCuidadoCompartilhadoEnum.TROCA_PROFISSIONAL_REFERENCIA,
  'Recondução de cuidado': CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO,
  'Agendada consulta com cidadão': CondutaCuidadoCompartilhadoEnum.AGENDADO_CONSULTA_CIDADAO_PRESENCIAL,
  'Agendada consulta entre profissionais': CondutaCuidadoCompartilhadoEnum.AGENDADO_INTERCONSULTA_SEM_CIDADAO,
  'Agendada consulta entre profissionais com o cidadão':
    CondutaCuidadoCompartilhadoEnum.AGENDADO_INTERCONSULTA_COM_CIDADAO,
  'Agendada consulta entre profissionais com o cidadão via videochamada':
    CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_COM_CIDADAO,
  'Agendada consulta entre profissionais via videochamada':
    CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_SEM_CIDADAO,
  'Sugerir agendamento para grupo': CondutaCuidadoCompartilhadoEnum.SUGESTAO_AGENDAMENTO_GRUPO,
  'Enviar para garantia de acesso': CondutaCuidadoCompartilhadoEnum.ENVIAR_GARANTIA_ACESSO,
}

export const IdentificadorCondutaCuidadoCompartilhadoDwRecord: Record<string, CondutaCuidadoCompartilhadoEnum> = {
  1: CondutaCuidadoCompartilhadoEnum.NOVA_PERGUNTA,
  2: CondutaCuidadoCompartilhadoEnum.PEDIDO_ESCLARECIMENTO,
  3: CondutaCuidadoCompartilhadoEnum.TELECONSULTORIA,
  4: CondutaCuidadoCompartilhadoEnum.PARECER_TECNICO_CONCLUSIVO,
  5: CondutaCuidadoCompartilhadoEnum.AGENDADO_INTERCONSULTA_SEM_CIDADAO,
  6: CondutaCuidadoCompartilhadoEnum.AGENDADO_CONSULTA_CIDADAO_PRESENCIAL,
  7: CondutaCuidadoCompartilhadoEnum.TROCA_PROFISSIONAL_REFERENCIA,
  8: CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO,
  9: CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_SEM_CIDADAO,
  10: CondutaCuidadoCompartilhadoEnum.AGENDADO_INTERCONSULTA_COM_CIDADAO,
  11: CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_COM_CIDADAO,
  12: CondutaCuidadoCompartilhadoEnum.SUGESTAO_AGENDAMENTO_GRUPO,
  13: CondutaCuidadoCompartilhadoEnum.ENVIAR_GARANTIA_ACESSO,
}

export const LabelPrioridadeCuidadoCompartilhadoDwRecord: Record<string, ClassificacaoPrioridadeCuidadoEnum> = {
  Baixa: ClassificacaoPrioridadeCuidadoEnum.BAIXA,
  Média: ClassificacaoPrioridadeCuidadoEnum.MEDIA,
  Alta: ClassificacaoPrioridadeCuidadoEnum.ALTA,
  'Muito alta': ClassificacaoPrioridadeCuidadoEnum.MUITO_ALTA,
}

export interface CuidadoCompartilhadoEvolucaoRecordModel
  extends Omit<CuidadoCompartilhadoEvolucaoQueryModel, 'lotacao' | 'lotacaoExecutante' | 'cuidadoCompartilhado'> {
  cuidadoCompartilhado: Omit<CuidadoCompartilhadoEvolucaoQueryModel['cuidadoCompartilhado'], 'lotacaoSolicitante'>
  lotacao: Omit<CuidadoCompartilhadoEvolucaoQueryModel['lotacao'], 'unidadeSaude' | 'cbo'>
}

const getTitleGenerico = (texto: string) => (
  evolucao: CuidadoCompartilhadoEvolucaoRecordModel,
  isFirstEvolucao: boolean
) => (
  <Text fontWeight='bold'>{`${evolucao.lotacao.profissional.nome} ${
    isFirstEvolucao ? 'compartilhou a discussão de caso' : texto
  }`}</Text>
)
const getTitleTrocaExecutante = (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) => (
  <HFlow hSpacing={0.25} alignItems='center'>
    <Text fontWeight='bold'>
      {evolucao.lotacao.profissional.nome} encaminhou para {evolucao.lotacaoAfetada.profissional.nome}{' '}
    </Text>
    <InfoIcon
      icon='infoCircleFilled'
      text={`${evolucao.lotacaoAfetada.profissional.nome} | ${evolucao.lotacaoAfetada.cbo.nome} | ${evolucao.lotacaoAfetada.equipe?.nome} | ${evolucao.lotacaoAfetada.unidadeSaude.nome}`}
    />
  </HFlow>
)
const getSubtitleAgendado = (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) => {
  const agendado = evolucao.agenda

  if (isUndefinedOrNull(agendado)) {
    return 'Agendamento não encontrado.'
  }

  const dateAndHoursFormatted = formatDateAndHoursMinutes(agendado.horarioInicial)
  switch (agendado.situacao) {
    case SituacaoAgendadoEnum.CANCELADO: {
      return `Agendamento para ${dateAndHoursFormatted} foi cancelado`
    }
    case SituacaoAgendadoEnum.EXCLUIDO: {
      return `Agendamento para ${dateAndHoursFormatted} foi excluído`
    }
    case SituacaoAgendadoEnum.NAO_COMPARECEU: {
      return `Cidadão não compareceu ao agendamento do dia ${dateAndHoursFormatted}`
    }
    case SituacaoAgendadoEnum.NAO_AGUARDOU: {
      return `Cidadão não aguardou ao agendamento do dia ${dateAndHoursFormatted}`
    }
    case SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO: {
      return `Agendamento foi realizado no dia ${dateAndHoursFormatted}`
    }
    default: {
      return `Agendado para ${dateAndHoursFormatted}`
    }
  }
}
const getDescriptionEvolDescricao = (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) => evolucao.descricao
const getDescriptionAgendadoObservacao = (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) =>
  evolucao.agenda?.observacao
const getBottomTextLabelAgendado = (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) => {
  const agendado = evolucao.agenda

  switch (agendado?.situacao) {
    case SituacaoAgendadoEnum.CANCELADO: {
      return 'Justificativa do cancelamento'
    }
    case SituacaoAgendadoEnum.EXCLUIDO: {
      return 'Justificativa da exclusão'
    }
    default: {
      return 'Justificativa'
    }
  }
}
const getBottomTextAgendado = (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) => {
  const agendado = evolucao.agenda
  return agendado?.situacao === SituacaoAgendadoEnum.CANCELADO || agendado?.situacao === SituacaoAgendadoEnum.EXCLUIDO
    ? agendado?.justificativaCancelamento?.justificativa
    : null
}

export const LabelSubtitleGarantiaAcessoRecord: Partial<Record<CondutaCuidadoCompartilhadoEnum, string>> = {
  AGENDADO_CONSULTA_CIDADAO_PRESENCIAL: 'Agendamento consulta com cidadão',
  AGENDADO_INTERCONSULTA_COM_CIDADAO: 'Agendamento consulta entre profissionais com o cidadão',
  AGENDADO_TELEINTERCONSULTA_COM_CIDADAO: 'Agendamento consulta entre profissionais com o cidadão via videochamada',
}

export const ActionEvolucaoCuidadoCompartilhadoRecord: Partial<Record<
  CondutaCuidadoCompartilhadoEnum,
  {
    Icon: FunctionComponent
    backgroundColor: Color
    getTitle: (evolucao: CuidadoCompartilhadoEvolucaoRecordModel, isFirstEvolucao: boolean) => React.ReactNode
    subtitleLabel?: string
    getSubtitle?: (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) => string
    descriptionLabel?: string
    getDescription: (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) => string
    getBottomTextLabel?: (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) => string
    getBottomText?: (evolucao: CuidadoCompartilhadoEvolucaoRecordModel) => string
  }
>> = {
  NOVA_PERGUNTA: {
    Icon: () => <Icon icon='chatFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('deu uma devolutiva'),
    descriptionLabel: LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.NOVA_PERGUNTA],
    getDescription: getDescriptionEvolDescricao,
  },
  PEDIDO_ESCLARECIMENTO: {
    Icon: () => <Icon icon='chatFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('deu uma devolutiva'),
    descriptionLabel: LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.PEDIDO_ESCLARECIMENTO],
    getDescription: getDescriptionEvolDescricao,
  },
  TELECONSULTORIA: {
    Icon: () => <Icon icon='chatFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('deu uma devolutiva'),
    descriptionLabel: LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.TELECONSULTORIA],
    getDescription: getDescriptionEvolDescricao,
  },
  PARECER_TECNICO_CONCLUSIVO: {
    Icon: () => <Icon icon='chatFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('deu uma devolutiva'),
    descriptionLabel:
      LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.PARECER_TECNICO_CONCLUSIVO],
    getDescription: getDescriptionEvolDescricao,
  },
  TROCA_PROFISSIONAL_REFERENCIA: {
    Icon: () => <Icon icon='exchange' />,
    backgroundColor: theme.pallete.surface.background,
    getTitle: getTitleTrocaExecutante,
    getDescription: getDescriptionEvolDescricao,
  },
  RECONDUCAO_CUIDADO: {
    Icon: () => <Icon icon='chatFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('deu uma devolutiva'),
    descriptionLabel: LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO],
    getDescription: getDescriptionEvolDescricao,
  },
  AGENDADO_CONSULTA_CIDADAO_PRESENCIAL: {
    Icon: () => <Icon icon='calendarFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('agendou uma consulta com o cidadão'),
    getSubtitle: getSubtitleAgendado,
    descriptionLabel: 'Observações',
    getDescription: getDescriptionAgendadoObservacao,
    getBottomTextLabel: getBottomTextLabelAgendado,
    getBottomText: getBottomTextAgendado,
  },
  AGENDADO_INTERCONSULTA_SEM_CIDADAO: {
    Icon: () => <Icon icon='calendarFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('agendou uma consulta entre profissionais sem a presença do cidadão'),
    getSubtitle: getSubtitleAgendado,
    descriptionLabel: 'Observações',
    getDescription: getDescriptionAgendadoObservacao,
    getBottomTextLabel: getBottomTextLabelAgendado,
    getBottomText: getBottomTextAgendado,
  },
  AGENDADO_INTERCONSULTA_COM_CIDADAO: {
    Icon: () => <Icon icon='calendarFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('agendou uma consulta entre profissionais com a presença do cidadão'),
    getSubtitle: getSubtitleAgendado,
    descriptionLabel: 'Observações',
    getDescription: getDescriptionAgendadoObservacao,
    getBottomTextLabel: getBottomTextLabelAgendado,
    getBottomText: getBottomTextAgendado,
  },
  AGENDADO_TELEINTERCONSULTA_SEM_CIDADAO: {
    Icon: () => <Icon icon='calendarFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('agendou uma consulta entre profissionais sem a presença do cidadão via videochamada'),
    getSubtitle: getSubtitleAgendado,
    descriptionLabel: 'Observações',
    getDescription: getDescriptionAgendadoObservacao,
    getBottomTextLabel: getBottomTextLabelAgendado,
    getBottomText: getBottomTextAgendado,
  },
  AGENDADO_TELEINTERCONSULTA_COM_CIDADAO: {
    Icon: () => <Icon icon='calendarFilled' />,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('agendou uma consulta entre profissionais com a presença do cidadão via videochamada'),
    getSubtitle: getSubtitleAgendado,
    descriptionLabel: 'Observações',
    getDescription: getDescriptionAgendadoObservacao,
    getBottomTextLabel: getBottomTextLabelAgendado,
    getBottomText: getBottomTextAgendado,
  },

  SUGESTAO_AGENDAMENTO_GRUPO: {
    Icon: IconHighFiveFilled,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('sugeriu um agendamento para grupo'),
    descriptionLabel: 'Observações',
    getDescription: getDescriptionEvolDescricao,
  },
  ENVIAR_GARANTIA_ACESSO: {
    Icon: IconEscutaInicial,
    backgroundColor: theme.pallete.surface.main,
    getTitle: getTitleGenerico('enviou a demanda para garantia de acesso'),
    subtitleLabel: 'Demanda',
    getSubtitle: (value) => LabelSubtitleGarantiaAcessoRecord[value.enviarGarantiaAcesso],
    descriptionLabel: 'Motivo',
    getDescription: getDescriptionEvolDescricao,
  },
}

export enum CuidadoCompartilhadoSortEnum {
  CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE = 'CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE',
  CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE = 'CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE',
  STATUS_EXECUTANTE = 'STATUS_EXECUTANTE',
  STATUS_SOLICITANTE = 'STATUS_SOLICITANTE',
}

export const CuidadoCompartilhadoSort: Record<CuidadoCompartilhadoSortEnum, string[]> = {
  CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE: ['-classificacaoPrioridade', 'status', 'ultimaAtualizacao'],
  CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE: ['-classificacaoPrioridade', '-status', 'ultimaAtualizacao'],
  STATUS_EXECUTANTE: ['status', '-classificacaoPrioridade', 'ultimaAtualizacao'],
  STATUS_SOLICITANTE: ['-status', '-classificacaoPrioridade', 'ultimaAtualizacao'],
}

type CondutasGroupsCuidadoCompartilhadoFieldsModel =
  | DevolutivaCuidadoCompartilhadoFieldsModel
  | PerguntaCuidadoCompartilhadoFieldsModel
  | TrocaExecutanteCuidadoCompartilhadoFieldsModel
  | AgendarConsultaCuidadoCompartilhadoFieldsModel
  | SugestaoAgendamentoGrupoCuidadoCompartilhadoFieldsModel

export const CondutaGroupRecord: Record<
  CondutaGroupEnum,
  {
    label: string
    validator: (now: Date) => ValidatorFunction<CondutasGroupsCuidadoCompartilhadoFieldsModel>
  }
> = {
  DEVOLUTIVA_DISCUSSAO_CASO: {
    label: 'Devolutiva de discussão do caso clínico',
    validator: () => devolutivaCuidadoCompartilhadoFieldValidator,
  },
  NOVA_PERGUNTA: {
    label: LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.NOVA_PERGUNTA],
    validator: () => perguntaCuidadoCompartilhadoFieldValidator,
  },
  TROCA_PROFISSIONAL_REFERENCIA: {
    label: LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.TROCA_PROFISSIONAL_REFERENCIA],
    validator: () => trocaExecutanteCuidadoCompartilhadoFieldValidator,
  },
  AGENDAR_CONSULTA: {
    label: 'Agendar consulta',
    validator: (now: Date) => agendarConsultaCuidadoCompartilhadoFieldValidator(now),
  },
  SUGESTAO_AGENDAMENTO_GRUPO: {
    label: LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.SUGESTAO_AGENDAMENTO_GRUPO],
    validator: () => sugestaoAgendamentoGrupoCuidadoCompartilhadoFieldValidator,
  },
  ENVIAR_GARANTIA_ACESSO: {
    label: 'Agendar consulta',
    validator: () => enviarGarantiaAcessoCuidadoCompartilhadoFieldValidator,
  },
}

export const CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER = 'Insira mais informações sobre a conduta selecionada'
