import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { DirtyFieldModalItemModel } from './model-dirtyFieldsModal'

interface DirtyFieldsModalItemProps {
  item: DirtyFieldModalItemModel
}

export function DirtyFieldsModalItem(props: DirtyFieldsModalItemProps) {
  const { item } = props

  if (!item.label && !item.fields) return null

  return !!item.fields?.isNotEmpty() || !item.showLabelOnModal ? (
    <VFlow vSpacing={0.25}>
      {item.fields.map((field) => (
        <DirtyFieldsModalItem key={field.label} item={field} />
      ))}
    </VFlow>
  ) : (
    <Text>{item.showLabelOnModal ? item.label : ''}</Text>
  )
}
