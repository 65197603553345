import { confirm } from 'components/modals/confirm'
import {
  convertDirtyFieldsStatusToModalItems,
  DirtyFieldsModal,
  DirtyFieldsModalConfig,
  DirtyFieldsObject,
  getDirtyFieldsObject,
} from 'components/modals/fields-summary'
import { ExecutionResult } from 'graphql'
import { RetificarAtendimentoMutation } from 'graphql/types.generated'
import React from 'react'
import { LabelMap } from 'util/fields-labels/model-fieldsLabels'
import { MetaRoot } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { soapFieldPaths } from 'view/atendimentos/detail/soap/fields-summary-modal/soapFormLabels'

import { AlertaRetificacaoAtendimentoText } from './AlertaRetificacaoAtendimentoText'

const IGNORED_FIELDS = ['_id', 'openedAccordions']

interface RetificacaoAtendimentoConfirmModalOptions<FormModel> {
  initialValues: FormModel
  currentValues: FormModel
  labels: LabelMap<FormModel>
  meta: MetaRoot<FormModel>
  config: DirtyFieldsModalConfig<FormModel>
  nomeCidadao: string
  onRetificar: () => Promise<ExecutionResult<RetificarAtendimentoMutation>>
  onRetificarSemAlteracoes: () => void
}

export function retificacaoAtendimentoConfirmModal<FormModel>(
  props: RetificacaoAtendimentoConfirmModalOptions<FormModel>
) {
  const { onRetificarSemAlteracoes, initialValues, currentValues, ...rest } = props

  const dirtyFieldsObject = getDirtyFieldsObject(initialValues, currentValues, soapFieldPaths, IGNORED_FIELDS)

  return isObjectDeepEmpty(dirtyFieldsObject)
    ? modalSemAlteracoes({ onRetificarSemAlteracoes })
    : modalComAlteracoes(rest, dirtyFieldsObject)
}

async function modalSegundaVerificacao<FormModel>(
  options: Pick<RetificacaoAtendimentoConfirmModalOptions<FormModel>, 'nomeCidadao' | 'onRetificar'>
) {
  const { nomeCidadao, onRetificar } = options

  const confirmed = await confirm({
    title: `Deseja confirmar a edição do registro do prontuário de ${nomeCidadao}?`,
    body: <AlertaRetificacaoAtendimentoText />,
  })()

  if (confirmed) {
    return onRetificar()
  }
}

async function modalSemAlteracoes<FormModel>(
  options: Pick<RetificacaoAtendimentoConfirmModalOptions<FormModel>, 'onRetificarSemAlteracoes'>
) {
  const { onRetificarSemAlteracoes } = options

  const confirmed = await confirm({
    title: 'Deseja finalizar sem retificar?',
    confirmLabel: 'Finalizar sem retificar',
    cancelLabel: 'Não, continuar aqui',
  })()

  if (confirmed) {
    return onRetificarSemAlteracoes()
  }
}

async function modalComAlteracoes<FormModel>(
  options: Omit<
    RetificacaoAtendimentoConfirmModalOptions<FormModel>,
    'onRetificarSemAlteracoes' | 'initialValues' | 'currentValues'
  >,
  dirtyFieldsObject: DirtyFieldsObject<FormModel>
) {
  const { labels, config, meta, ...rest } = options
  const items = convertDirtyFieldsStatusToModalItems(dirtyFieldsObject, labels, config, meta)

  const confirmed = await confirm({
    size: 'auto',
    render: () => <DirtyFieldsModal items={items} />,
  })()

  if (confirmed) {
    return modalSegundaVerificacao(rest)
  }
}
