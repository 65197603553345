/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { TableCell, TableRow, Text, VFlow } from 'bold-ui'
import { isUndefinedOrNull } from 'util/checks'

import { DirtyFieldsModalItem } from './DirtyFieldsModalItem'
import { DirtyFieldModalItemModel } from './model-dirtyFieldsModal'

interface DirtyFieldsModalSectionProps {
  section: DirtyFieldModalItemModel
}

export function DirtyFieldsModalSection(props: DirtyFieldsModalSectionProps) {
  const { section } = props

  if (
    isUndefinedOrNull(section?.fields) ||
    (section?.fields.isEmpty() && isUndefinedOrNull(section?.noFieldsPlaceholder))
  )
    return null

  const styles = createStyles(section.titleColor)

  return (
    <TableRow>
      <TableCell style={styles.cellSection}>
        <Text style={styles.textSection} fontWeight='bold'>
          {section.label}
        </Text>
      </TableCell>
      <TableCell style={styles.cellCampo}>
        <VFlow vSpacing={0.1}>
          {section.fields.isEmpty() ? (
            <Text>{section.noFieldsPlaceholder}</Text>
          ) : (
            section.fields.map((field, key) => <DirtyFieldsModalItem key={key} item={field} />)
          )}
        </VFlow>
      </TableCell>
    </TableRow>
  )
}

const createStyles = (titleColor: string) => ({
  textSection: css`
    ${titleColor && `color: ${titleColor};`}
  `,
  cellSection: css`
    padding: 0.5rem 1rem;
    vertical-align: top;
  `,
  cellCampo: css`
    padding: 0.5rem 1rem;
  `,
})
